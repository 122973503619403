/**
 * @param { String } input e.g 25/01/2023, 15-01-2023
 */
module.exports.GetUTCDateFromDDMMYYYY = (input) => {
    let numbers = input.split("/").map(x => Number(x));
    if (numbers.length != 3) {
        numbers = input.split("-").map(x => Number(x));
        if (numbers.length != 3) return null;
    }

    return new Date(Date.UTC(numbers[2], numbers[1] - 1, numbers[0]));
};

/**
 * @param { String } input e.g 2023/01/25, 2023-01-25
 */
module.exports.GetUTCDateFromYYYYMMDD = (input) => {
    let numbers = input.split("/").map(x => Number(x));
    if (numbers.length != 3) {
        numbers = input.split("-").map(x => Number(x));
        if (numbers.length != 3) return null;
    }

    return new Date(Date.UTC(numbers[0], numbers[1] - 1, numbers[2]));
};

/**
 * @param { String } input e.g 2023/01/25, 2023-01-25
 */
module.exports.GetUTCDateFromMMYYYY = (input) => {
    let numbers = input.split("/").map(x => Number(x));
    if (numbers.length != 2) {
        numbers = input.split("-").map(x => Number(x));
        if (numbers.length != 2) return null;
    }

    return new Date(Date.UTC(numbers[1], numbers[0] - 1));
};

/**
 * @param { String } input e.g 24/02/2023
 * @returns { String | null } e.g 2023-02-24 if can convert, else null
 */
module.exports.ConvertToYYYYMMDDFromDDMMYYYY = (input) => {
    const parts = input.split("/");
    if (parts.length != 3) return null;

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

/**
 * @param { String } input e.g 02-2023, 1-2023
 * @returns { String | null } e.g 2023-02-24 if can convert, else null
 */
module.exports.ConvertToYYYYMMDDFromMMYYYY = (input) => {
    let parts = input.split("/");
    if (parts.length != 2) {
        parts = input.split("-");
        if (parts.length != 2) return null;
    }

    return `${parts[1]}-${(parts[0]).padStart(2, "0")}-01`;
};

/**
 * @param { String } input e.g 2023-02-13
 * @returns { String | null } e.g 13-02 if can convert, else null
 */
module.exports.ConvertToDDMMFromISO8601 = (input) => {
    const parts = input.split("-");
    if (parts.length != 3) return null;

    return `${parts[2]}-${parts[1]}`;
};

/**
 * @param { String } input e.g 2024-03-02T07:18:18.300Z
 * @returns { String | null } e.g 02-03-2024 if can convert, else null
 */
module.exports.ConvertFromIsoDateToDDMMYYYY = (input) => {
    const date = new Date(input);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

/**
 * @param { String } input e.g 25/01/2023
 */
module.exports.GetTimeFromDDMMYYYY = (input) => {
    const numbers = input.split("/").map(x => Number(x));
    if (numbers.length != 3) return null;

    return Date.UTC(numbers[2], numbers[1] - 1, numbers[0]);
};

/**
 * @param { String } input e.g 02-2023, 02/2023
 */
module.exports.ValidateMMYYYY = (input) => {
    let numbers = input.split("/").map(x => Number(x));
    if (numbers.length != 2) {
        numbers = input.split("-").map(x => Number(x));
        if (numbers.length != 2) return null;
    }

    const stringYYYYMMDD = this.ConvertToYYYYMMDDFromMMYYYY(input);
    const dateYYYYMMDD = this.GetUTCDateFromYYYYMMDD(stringYYYYMMDD);

    return numbers[0] > 0 && numbers[0] <= 12 && dateYYYYMMDD > 0;
};

/**
 * @param { String } input e.g 02-2023
 */
module.exports.ValidateMMYYYYOnly_ = (input) => {
    const numbers = input.split("-").map(x => Number(x));
    if (numbers.length != 2) return null;

    const stringYYYYMMDD = this.ConvertToYYYYMMDDFromMMYYYY(input);
    const dateYYYYMMDD = this.GetUTCDateFromYYYYMMDD(stringYYYYMMDD);

    return numbers[0] > 0 && numbers[0] <= 12 && dateYYYYMMDD > 0;
};

/**
 * @param { Date } date1 e.g 2023-02-13
 * @param { Date } date2 e.g 2023-02-15
 * @returns { Number } e.g 2
 */
module.exports.GetDaysDifference = (date1, date2) => {
    const difference = date2.getTime() - date1.getTime();
    return difference / (1000 * 3600 * 24);
};

module.exports.GetCurrentDDMMYYYYDate = () => {
    const now = new Date();
    const day = now.getUTCDate().toString().padStart(2, "0");
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = now.getUTCFullYear();
    return `${day}-${month}-${year}`;
};

module.exports.GetCurrentMMYYYYDate = () => {
    const now = new Date();
    return `${(now.getUTCMonth() + 1).toString().padStart(2, "0")}-${now.getUTCFullYear()}`;
};

module.exports.GetPreviousMMYYYYDate = () => {
    const now = new Date();
    // Obtener el mes actual y restar 1, ajustando si es enero
    const previousMonth = now.getUTCMonth() === 0 ? 11 : now.getUTCMonth() - 1;
    // Obtener el año actual, ajustando si es enero (se retrocede un año)
    const year = now.getUTCMonth() === 0 ? now.getUTCFullYear() - 1 : now.getUTCFullYear();
    return `${(previousMonth + 1).toString().padStart(2, "0")}-${year}`;
};

module.exports.GetDateXMonthsAgo = (monthsToSubtract) => {
    const now = new Date();
    now.setMonth(now.getMonth() - monthsToSubtract);
    now.setDate(1);
    return now;
};

module.exports.GetMMYYYYFormatDate = (date) => {
    return `${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date.getUTCFullYear()}`;
};

module.exports.GetPreviousMonthRange = (monthsToSubtract) => {
    const currentDate = new Date();
    const dateFrom = new Date(currentDate);

    // Restar los meses especificados
    dateFrom.setUTCMonth(dateFrom.getUTCMonth() - monthsToSubtract);

    // Ajustar si el mes resultante es enero (restar un año)
    if (dateFrom.getUTCMonth() === 0) {
        dateFrom.setUTCFullYear(dateFrom.getUTCFullYear() - 1);
    }

    const formattedDateFrom = `${(monthsToSubtract === 12 ? dateFrom.getUTCMonth() : dateFrom.getUTCMonth() + 1).toString().padStart(2, "0")}-${dateFrom.getUTCFullYear()}`;
    const formattedDateTo = this.GetPreviousMMYYYYDate();

    return {
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo
    };
};

/**
 * @param { Date } date 
 */
module.exports.GetDDMMYYYY = (date) => {
    return date.toISOString().slice(0, 10).split("-").reverse().join("/");
};

module.exports.GetDDMMYYYYFromTZ = (tzStringDate) => {
    const tzDate = new Date(tzStringDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return tzDate.toLocaleDateString("es-ES", options);
};

module.exports.GetMMYYYYDateFromYYYYMMDD = (input) => {
    const values = input.split("-").map(x => Number(x));
    if (values.length != 3) return null;

    return `${values[1].toString().padStart(2, "0")}-${values[0]}`;
};

module.exports.GetYYYYMMDDFormat = (date) => {
    return date.toISOString().slice(0, 10);
};

module.exports.GetPrevMonthDate = () => {
    const now = new Date();
    const prevMonth = new Date(now);
    prevMonth.setUTCMonth(prevMonth.getUTCMonth() - 1);
    return prevMonth;
};
module.exports.GetPrevDayDate = () => {
    const now = new Date();
    const prevDay = new Date(now);
    prevDay.setUTCDate(prevDay.getUTCDate() - 1);
    return prevDay;
};

