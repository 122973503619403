import { AxiosGetAsync, AxiosPostAsync } from "./base.services";

const SatelitalImageService = () => { };

/**
 * @param {Number} toolFieldId 
 * @param {string} dateFrom in format yyyy-MM-dd
 * @param {string} dateTo in format yyyy-MM-dd
 * @param {string} imageType NDVI, EVI or Infrarrojo
 * @param {number} maxCloudCoverage 
 * @param {number} numberOfImagesPerMonth
 */
SatelitalImageService.GetSatelitalImagesAsync = async (
    toolFieldId,
    dateFrom,
    dateTo,
    imageType,
    maxCloudCoverage,
    numberOfImagesPerMonth
) => {
    const result = await AxiosGetAsync(
        `${process.env.VUE_APP_API_URL}/v1/satelital-images/${toolFieldId}/${dateFrom}/${dateTo}/${imageType}?maxCloudCoverage=${maxCloudCoverage}&numberOfImagesPerMonth=${numberOfImagesPerMonth}`,
    );

    return result.data;
};

SatelitalImageService.CreateSHPFileForSatelitalImagesAsync = async (Ids) => {
    console.log({ ids: Ids });
    const result = await AxiosPostAsync(`${process.env.VUE_APP_API_URL}/v1/satelital-images`, { ids: Ids });
    return result.data;
};

export default SatelitalImageService;
