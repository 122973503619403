import UserPreferences from "../../config/user-preferences.config";

export default {
    namespaced: true,
    state: {
        mapOpacity: UserPreferences.getMapOpacity(),
        mapZoom: UserPreferences.getMapZoom(),
        groupMapZoom: []
    },
    getters: {
        getGroupMapZoom(state) {
            return state.groupMapZoom;
        }
    },
    mutations: {
        setOpacity(state, value) {
            state.mapOpacity = value;
            UserPreferences.setMapOpacity(value);
        },
        setMapZoom(state, { zoomGroupName, zoom }) {
            state.mapZoom = zoom;

            if (zoomGroupName) state.groupMapZoom[zoomGroupName] = zoom;

            UserPreferences.setMapZoom(zoomGroupName, zoom);
        },
    },
    actions: {
        setOpacity({ commit }, value) {
            commit("setOpacity", value);
        },
        setMapZoom({ commit }, { zoomGroupName, zoom }) {
            commit("setMapZoom", { zoomGroupName, zoom });
        }
    },
};
