export default {
    computed: {
    },
    mounted() {
        //CUIDADO! Se ejecutaría en todas las instancias de todo componente.
    },
    methods: {
        WarmConfirm(text, func) {
            this.Confirm("ATENCIÓN", text, "warning", func);
        },
        Confirm(title, text, icon, func) {
            const result = this.$swal.mixin({
                customClass: {
                    confirmButton: "Boton BotonSi",
                    cancelButton: "Boton BotonNo",
                },
                buttonsStyling: false,
            });
            result
                .fire({
                    title: title,
                    text: text,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonText: "SI",
                    cancelButtonText: "NO",
                    reverseButtons: true
                })
                .then(result => {
                    if (result.isConfirmed) {
                        //_This.DoTheFetch(establecimiento);
                        func();
                    }
                });
        },
        OkConfirm(text, func) {
            const result = this.$swal.mixin({
                customClass: {
                    confirmButton: "Boton BotonOK",
                },
                buttonsStyling: false,
            });

            result
                .fire({
                    text: text,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                })
                .then(() => {
                    func();
                });
        }
    }
};
