import { render, staticRenderFns } from "./Titulo.vue?vue&type=template&id=65785936&scoped=true"
import script from "./Titulo.vue?vue&type=script&lang=js"
export * from "./Titulo.vue?vue&type=script&lang=js"
import style0 from "./Titulo.vue?vue&type=style&index=0&id=65785936&prod&style=true&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65785936",
  null
  
)

export default component.exports