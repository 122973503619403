<template>
    <v-row no-gutters class="p-relative">
        <div :id="`mapa-${_uid}`" class="map-styles" :style="getStyles"></div>
        <div class="slider-map">
            <v-slider v-model="myMapOpacity" :prepend-icon="mdiCircleOpacity" :dark="true" color="white"
                thumb-color="white" min="0" max="1" step="0.1"></v-slider>
        </div>
    </v-row>
</template>

<script>
/* eslint-disable */
import { GetUSGSOverlay, GetDefaultGmapSettings } from "../../services/google.services.js";
import { mapState, mapActions, mapGetters } from "vuex";
import { mdiCircleOpacity, mdiCrosshairsGps } from "@mdi/js";
import { ambientationsMapZoomRanges } from "../../utils/general.utils";
import { NotifyError } from "../../helpers/vue-notifications.helper.js"

export default {
    props: {
        geoImage: {
            type: Object,
            require: true
        },
        height: {
            type: String,
            default: "30vh"
        },
        customMapZoom: {
            type: Number,
            required: false,
            default: null
        },
        zoomGroupName: {
            type: String,
            required: false,
            default: "Default-Map-Zoom"
        }
    },
    data() {
        return {
            map: null,
            overlay: null,
            mdiCircleOpacity,
            mdiCrosshairsGps,
            myMapOpacity: 0,
            geolocationMarker: null,
            watchIds: []
        }
    },
    async mounted() {
        this.myMapOpacity = this.mapOpacity;
        await this.InitMapAsync();
    },
    beforeDestroy() {
        this.ClearAllWatchIds();
        this.setGeolocationEnablement(false);
    },
    computed: {
        ...mapState("userPreferences", ["mapOpacity", "mapZoom"]),
        ...mapGetters("satelitalMapStore", ["getGeolocationEnablement"]),
        ...mapGetters("userPreferences", ["getGroupMapZoom"]),
        getGeoImage() {
            if (this.geoImage) return this.geoImage;

            return null;
        },
        getSrcGeoImage() {
            return this.getGeoImage.src;
        },
        getStyles() {
            const result = {};

            if (this.height) result.height = this.height;

            return result;
        }
    },
    methods: {
        ...mapActions("userPreferences", ["setOpacity", "setMapZoom"]),
        ...mapActions("satelitalMapStore", ["setGeolocationEnablement"]),
        SaveMapZoomInLocalStorage(zoomValue) {
            this.setMapZoom({ zoomGroupName: this.zoomGroupName, zoom: zoomValue });
        },
        CalculateDynamicZoomForAmbientationsMap(imageWidth, imageHeight) {
            const aspectRatio = Math.abs(imageWidth / imageHeight);
            const zoomRange = ambientationsMapZoomRanges.find(range => aspectRatio <= range.maxRatio);

            const dynamicZoom = zoomRange ? zoomRange.zoom : 13;
            return dynamicZoom;
        },
        async watchUserLocation() {
            if (!("geolocation" in navigator)) {
                NotifyError("La geolocalización no es soportada por este navegador.");
                return;
            }

            const watchId = navigator.geolocation.watchPosition(position => {
                const { latitude, longitude } = position.coords;
                const userLatLng = new google.maps.LatLng(latitude, longitude);

                if (!this.map) return;

                this.map.setCenter(userLatLng);

                if (!this.geolocationMarker) {
                    this.geolocationMarker = new google.maps.Marker({
                        position: userLatLng,
                        map: this.map,
                        icon: {
                            path: mdiCrosshairsGps,
                            scale: 0.7,
                            fillColor: "blue",
                            fillOpacity: 1,
                            strokeWeight: 0
                        }
                    });
                }

                if (this.geolocationMarker) {
                    this.geolocationMarker.setPosition(userLatLng);
                }
            });

            this.watchIds.push(watchId);
        },
        ClearAllWatchIds() {
            this.watchIds.forEach(id => navigator.geolocation.clearWatch(id));
            this.watchIds = [];
            this.geolocationMarker = null;
        },
        async InitMapAsync() {
            if (this.getGeoImage == null) return;

            const fielImage = await this.CreateImageToKnowTheSizeOfTheField();

            const bounds = this.GetBounds();
            const ambientationMapZoom = this.HasCustomMapZoom() ? this.HasCustomMapZoom() : this.CalculateDynamicZoomForAmbientationsMap(fielImage.width, fielImage.height);

            const ambientationMap = document.getElementById(`mapa-${this._uid}`);
            if (!ambientationMap) return;

            const options = GetDefaultGmapSettings();
            options.center = this.getGeoImage.location.center;
            options.zoom = ambientationMapZoom;

            // eslint-disable-next-line no-undef
            const { Map } = await google.maps.importLibrary("maps");
            this.map = new Map(ambientationMap, options);

            this.map.addListener("tilesloaded", () => {
                const currentZoom = this.map.getZoom();
                this.SaveMapZoomInLocalStorage(currentZoom);
            });

            const srcImage = `${this.getSrcGeoImage}`;
            this.SetOverlay(bounds, srcImage);
        },
        HasCustomMapZoom() {
            if (this.customMapZoom) return this.customMapZoom;

            if (this.getGroupMapZoom[this.zoomGroupName]) return this.getGroupMapZoom[this.zoomGroupName];

            if (localStorage.getItem(this.zoomGroupName)) return parseInt(localStorage.getItem(this.zoomGroupName));

            return false;
        },
        async CreateImageToKnowTheSizeOfTheField() {
            const image = new Image();
            image.src = this.getGeoImage.src;

            await new Promise((resolve) => {
                image.onload = resolve;
            });

            return image;
        },
        SetOverlay(bounds, srcImage) {
            this.overlay = GetUSGSOverlay(bounds, srcImage, this.mapOpacity);
            this.overlay.setMap(this.map, { disableAutoPan: true });
        },
        GetBounds() {
            const result = new google.maps.LatLngBounds(
                new google.maps.LatLng(this.getGeoImage.location.SW.lat, this.getGeoImage.location.SW.lng),
                new google.maps.LatLng(this.getGeoImage.location.NE.lat, this.getGeoImage.location.NE.lng)
            );
            return result;
        },
        DeleteMapOverlay() {
            this.overlay.setMap(null);
        }
    },
    watch: {
        ambientationInfo: {
            handler: 'UpdatePlottedImage',
            deep: true
        },
        myMapOpacity(newVal) {
            this.setOpacity(newVal);
        },
        mapOpacity(newVal) {
            this.myMapOpacity = newVal;
            if (this.overlay) this.overlay.setOpacity(newVal);
        },
        mapZoom(newVal) {
            if (!this.map) return;

            if (this.getGroupMapZoom[this.zoomGroupName]) {
                this.map.setZoom(this.getGroupMapZoom[this.zoomGroupName]);
            }
        },
        async getGeolocationEnablement(newVal) {
            if (!this.getGeolocationEnablement) {
                this.ClearAllWatchIds();
                await this.InitMapAsync();
            }
            if (this.getGeolocationEnablement) this.watchUserLocation();
        },
        geoImage: {
            handler: 'InitMapAsync',
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
#ambientationMap {
    height: 600px;
}

.map-styles {
    height: 500px;
    width: 100%;
}

.slider-map {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35%;
}
</style>
