import PenetrometryServices from "../../../services/penetrometry.services";

const getDefaultState = () => {
    return {
        processesInfo: null
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getPenetrometryProcessesInfo(state) {
            return state.processesInfo;
        },
        getPenetrometryLastProccessToShowToProducer(state) {
            if (state.processesInfo == null) return null;

            if (state.processesInfo.records.length === 0) return null;

            return state.processesInfo.records[0].IdProcess;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setPenetrometryProcessesInfo(state, value) {
            if (value == null) return;

            state.processesInfo = value;
            state.processesInfo.records.sort((a, b) => b.IdProcess - a.IdProcess);
        },
        updatePenetrometryProcessState(state, { processId, processState }) {
            const process = state.processesInfo?.records.find(x => x.IdProcess === processId);
            if (process) process.IdState = processState;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setPenetrometryProcessesInfo({ commit }, toolFieldId) {
            const data = await PenetrometryServices.GetPenetrometryProcessListInfoByToolFieldIdAsync(toolFieldId);
            if (!data) return;

            commit("setPenetrometryProcessesInfo", data);
        },
        updatePenetrometryProcessState({ commit }, { processId, processState }) {
            commit("updatePenetrometryProcessState", { processId, processState });
        },
    }
};

