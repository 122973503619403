<template>
    <div class="new-version" id="NewAvailableVersionDiv">🎊 Nueva versión disponible 🎊 <br> ACTUALIZAR AHORA</div>
</template>

<script>
const newVersion = process.env.VUE_APP_VERSION;
export default {
    data() {
        return {
            newVersion
        };
    }
};
</script>

<style lang="scss" scoped>
.new-version {
    position: fixed;
    display: none;
    bottom: 5px;
    right: 5px;
    z-index: 9999;
    color: #000;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 1.2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
    width: 420px;
    max-width: calc(100% - 10px);
    cursor: pointer;
    background: linear-gradient(270deg, #FFF, #07A55B, #FFF);
    -webkit-animation: MyAnimation 7s ease infinite;
    -moz-animation: MyAnimation 7s ease infinite;
    animation: MyAnimation 7s ease infinite;
    background-size: 600% 600%;

    @media(max-width: 800px) {
        width: 100%;
    }
}

@-webkit-keyframes MyAnimation {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes MyAnimation {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes MyAnimation {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}
</style>
