import { AxiosGetAsync } from "./base.services";

const ShowMapServices = () => { };

ShowMapServices.GetInfoByToolAsync = async (endpointUrl) => {
    const urlGet = `${process.env.VUE_APP_API_URL}/${endpointUrl}`;
    const result = await AxiosGetAsync(urlGet);
    return result.data;
};
export default ShowMapServices;
