import AmbientationServices from "../../../services/ambientation-records.services";

const getDefaultState = () => {
    return {
        processesInfo: null
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getProcessesInfo(state) {
            return state.processesInfo;
        },
        getLastProccessToShowToProducer(state) {
            if (state.processesInfo == null) return null;

            if (state.processesInfo.records.length === 0) return null;

            return state.processesInfo.records[0];
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setProcessesInfo(state, value) {
            if (value == null) return;

            state.processesInfo = value;
            state.processesInfo.records.sort((a, b) => b.IdProcess - a.IdProcess);
        },
        updateProcessState(state, { processId, processState }) {
            const process = state.processesInfo?.records.find(x => x.IdProcess === processId);
            if (process) process.IdState = processState;
        },
        setVisibilityOfAProcess(state, { processId, visible }) {
            const process = state.processesInfo?.records.find(x => x.IdProcess === processId);
            if (process) process.VisibleForClients = visible;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setProcessesInfo({ commit }, toolFieldId) {
            const data = await AmbientationServices.GetProcessAsync(toolFieldId);
            if (!data) return;

            commit("setProcessesInfo", data);
        },
        updateProcessState({ commit }, { processId, processState }) {
            commit("updateProcessState", { processId, processState });
        },
        async setVisibilityOfAProcessAsync({ commit }, { toolFieldId, processId, visibility }) {
            const data = await AmbientationServices.UpdateProcessVisibilityAsync({ toolFieldId, processId, visibility });
            if (data) commit("setVisibilityOfAProcess", { processId, visible: visibility });
        }
    }
};

