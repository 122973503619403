import PrescriptionServices from "../../../services/prescription.services";

export default {
    namespaced: true,
    state: {
        ambientationsInfo: null
    },
    getters: {
        getPrescriptionAmbientations(state) {
            if (state.ambientationsInfo == null) return null;

            return state.ambientationsInfo;
        },
    },
    mutations: {
        setPrescriptionAmbientationsInfo(state, value) {
            if (value == null) return;

            state.ambientationsInfo = value;
        }
    },
    actions: {
        async setPrescriptionAmbientationsInfo({ commit }, { toolFieldId, processId }) {
            const data = await PrescriptionServices.GetPrescriptionFullProcessInfoAsync(toolFieldId, processId);
            if (!data) return;

            commit("setPrescriptionAmbientationsInfo", data);
        },
    }
};
