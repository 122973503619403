<template>
    <span class="pwa-buttons-container" id="pwaPanel">
        <v-btn v-if="showPWAInstallBtn" @click="InstallPWA()" :disabled="processing" variant="elevated"
            class="my-special-button">
            <svg-icon type="mdi" :path="mdiDownload" class="w-100 h-100"></svg-icon>
            App
        </v-btn>
        <v-btn v-if="showPWAInstallBtn" @click="BtnHidePwa()" variant="fab" class="my-special-button-x">
            X
        </v-btn>
    </span>
</template>

<script>
import { mdiDownload } from "@mdi/js";
export default {
    data() {
        return {
            processing: false,
            showPWAInstallBtn: false,
            deferredPrompt: null,
            mdiDownload
        };
    },
    async mounted() {
        //TODO: remove PWAINstalled, instance of import it
        if ((await this.PWAInstalled()) == false && this.SWInstalled()) {
            window.addEventListener("beforeinstallprompt", (event) => {
                console.log("👍", "beforeinstallprompt", event);
                // Prevent the mini-infobar from appearing on mobile
                event.preventDefault();
                // Stash the event so it can be triggered later.
                this.deferredPrompt = event;
                // Remove the 'hidden' class from the install button container
                this.showPWAInstallBtn = true;
            });

            window.addEventListener("appinstalled", (event) => {
                console.log("👍", "appinstalled", event);
                // Clear the deferredPrompt so it can be garbage collected
                this.deferredPrompt = null;
            });

            // Valid if the button has to be shown
            const nextDisplayDate = localStorage.getItem("hidePwaButton");
            const today = new Date();

            if (nextDisplayDate && nextDisplayDate > today.getTime())
                this.HidePwaPanel();
            else
                this.ShowPwaPanel();
        }
    },
    methods: {
        async SWInstalled() {
            const swInstalledLength = await navigator.serviceWorker.getRegistrations().then(registrations => {
                return registrations.length;
            });
            return (swInstalledLength > 0);
        },
        PWAInstalled() {
            // For iOS
            if (window.navigator.standalone) return true;

            // For Android
            if (window.matchMedia("(display-mode: standalone)").matches) return true;

            // If neither is true, it's not installed
            return false;
        },
        async InstallPWA() {
            console.log("👍", "InstallPWAButton-clicked");
            const promptEvent = this.deferredPrompt;
            if (!promptEvent) {
                // The deferred prompt isn't available.
                return;
            }
            // Show the install prompt.
            promptEvent.prompt();
            // Log the result
            const result = await promptEvent.userChoice;
            console.log("👍", "userChoice", result);
            if (result.outcome === "accepted") {
                //PWAResourcesLoader();
            }
            // Reset the deferred prompt variable, since
            // prompt() can only be called once.
            this.deferredPrompt = null;
            // Hide the install button.
            this.showPWAInstallBtn = false;
            //setTimeout(() => { window.location.reload(); }, 8000);// To ensure the app is updated 
        },
        BtnHidePwa() {
            this.HidePwaPanel();
            this.setLocalStorage();
        },
        setLocalStorage() {
            const nextDisplayDate = new Date();
            nextDisplayDate.setDate(nextDisplayDate.getDate() + 7); // Add 7 days to the current date
            localStorage.setItem("hidePwaButton", nextDisplayDate.getTime());
        },
        ShowPwaPanel() {
            const pwaPanel = document.getElementById("pwaPanel");
            pwaPanel.style.display = "block";
        },
        HidePwaPanel() {
            const pwaPanel = document.getElementById("pwaPanel");
            pwaPanel.style.display = "none";
        }
    }
};

</script>

<style lang="scss" scoped>
.pwa-buttons-container {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
}

.my-special-button {
    background-color: $success;
    color: #FFF;
    font-weight: 600;
    margin-right: 4px;
}

.my-special-button-x {
    background: grey;
    width: 15px;
    color: black;
    font-weight: 600;
    margin-right: -4px;
}
</style>
