<template>
    <div v-if="showBackButton" @click="BackRoute" class="my-back-button mr-lg-4 d-flex">
        <svg-icon type="mdi" :path="mdiArrowLeft" class="w100 h100" />
    </div>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";
export default {
    name: "MyBackButton",
    data() {
        return {
            mdiArrowLeft,
            showBackButton: false
        };
    },
    mounted() {
        this.SetBackButtonVisibility();
    },
    methods: {
        SetBackButtonVisibility() {
            const HideOnPages = ["Home", "Login"];
            this.showBackButton = !HideOnPages.includes(this.$router.currentRoute.name);
        },
        BackRoute() {
            this.$router.go(-1);
        }
    },
    watch: {
        $route() {
            this.SetBackButtonVisibility();
        },
    },
};
</script>

<style lang="scss" scoped>
.my-back-button {
    display: flex;
    // position: absolute;
    // z-index: 9;
    // margin: 15px;
    height: 34px;
    width: 34px;
    cursor: pointer;
    font-weight: 600;
    color: #FFFFFF;
}

.map-section {
    position: fixed;
    z-index: 9;
}
</style>
