import { AxiosGetAsync, AxiosPostAsync, AxiosDeleteAsync } from "./base.services";
// eslint-disable-next-line no-unused-vars
import NewPrescription from "./models/prescriptions-models";

const PrescriptionServices = () => {};
/**
 *  @param { Number } toolFieldId
 */
PrescriptionServices.GetPrescriptionProcessListInfoByToolFieldIdAsync = async (toolFieldId) => {
    if (!Number.isInteger(toolFieldId)) return null;

    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/prescription/processes-by-field/${toolFieldId}`);
    if (result.data.records.length > 0) result.data.records.sort((a, b) => b.processId - a.processId);
    return result.data;
};
/**
 *  @param { Number } toolFieldId
 * @param { Number } processId
 */
PrescriptionServices.GetPrescriptionFullProcessInfoAsync = async (toolFieldId, processId) => {
    if (!Number.isInteger(toolFieldId) && !Number.isInteger(processId)) return null;

    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/prescription/${toolFieldId}/${processId}`);
    return result.data;
};

/**
 * @param { Number } fieldId
 * @param { FormData } formData
 */
PrescriptionServices.UploadPrescriptionFileAsync = async (fieldId, formData) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/prescription/file/${fieldId}`;
    const result = await AxiosPostAsync(url, formData);
    return result;
};
/**
 * @param { Number } processId
 */
PrescriptionServices.DeletePrescriptionProcessByIdAsync = async (processId) => {
    const result = await AxiosDeleteAsync(`${process.env.VUE_APP_API_URL}/v1/prescription/process/${processId}`);
    return result.null == null;
};

/**
 * @param { Number } ambientationId
 * @param { NewPrescription } newPrescriptionBody
 */
PrescriptionServices.GeneratePrescriptionByAmbientationIdAsync = async (ambientationId, newPrescriptionBody) => {
    const result = await AxiosPostAsync(`${process.env.VUE_APP_API_URL}/v1/prescription/create/${ambientationId}`, newPrescriptionBody);

    return result.error == null;
};

export default PrescriptionServices;
