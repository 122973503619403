module.exports.ColumnasTabla = Object.freeze({
    Productor: "Productor",
    Establecimiento: "Establecimiento",
    Fecha: "Fecha",
    Localidad: "Localidad",
});

const IsAnUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE));
    if (user == null) return false;
    return user.RolUsuario == 99;
};

module.exports.Herramientas = {
    Aguas: {
        id: 3,
        routeName: "UsefulWater",
        description:
            "Informe elaborado a partir del muestreo de suelo, para cuantificar la recarga hídrica del perfil y de esta manera cuantificar la lámina de agua (mm) aprovechable por los cultivos.",
        title: "Análisis de agua útil",
        icon: "water",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, toolFieldId },
            };
        },
    },
    Satelitales: {
        id: 6,
        routeName: "Satelitals",
        description:
            "Estudio digital de tu lote; a través del análisis de imágenes satelitales obtenidas del software AtFarm, te brindamos un reporte del diagnóstico del crecimiento de tu cultivo.",
        title: "Seguimiento del Cultivo con Imágenes Satelitales (Atfarm)",
        icon: "satellite",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, toolFieldId },
            };
        },
    },
    Pluviometros: {
        id: 4,
        name: "Pluviómetros",
        routeName: "Pluviometers",
        description: "Consiste en el registro mensual de la precipitación acumulada a lo largo del ciclo de cultivo.",
        title: "Registro pluviométrico mensual",
        icon: "cloud-showers-heavy",
        value: 0,
        unit: "mm",
        showMapEndpoint: "pluviometers-info",
        GetPluviometersPathFromPluviometerList: (pluviometer) => {
            const { _IdProductor, _IdEstablecimiento, _IdPluviometro } = pluviometer;
            return `/producers/${_IdProductor}/fields/${_IdEstablecimiento}/pluviometers/${_IdPluviometro}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, pluviometerId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, pluviometerId },
            };
        },
    },
    Freatimetros: {
        id: 5,
        name: "Freatímetros",
        routeName: "Freatimeters",
        description: "Consiste en el registro mensual de la variación del nivel de napa freática a lo largo del ciclo de cultivo.",
        title: "Registro de napa freática mensual",
        icon: "ruler-vertical",
        value: 1,
        unit: "m",
        showMapEndpoint: "freatimeters-info",
        GetPhreatimetersPathFromPhreatimeterList: (freatimeter) => {
            const { _IdProductor, _IdEstablecimiento, _IdFreatimeter } = freatimeter;
            return `/producers/${_IdProductor}/fields/${_IdEstablecimiento}/phreatimeters/${_IdFreatimeter}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, phreatimeterId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, phreatimeterId },
            };
        },
    },
    Ambientaciones: {
        id: 7,
        routeName: "AmbientationsSection1",
        description:
            "Diagnóstico de suelo  elaborado con la información obtenida del relevamiento del lote con las herramientas tecnológicas de alta precisión e innovación que contamos; escáner de suelo, Soil Optix, Rastra Veris, calador hidráulico; Penetrometría, imagénes satelitales y mapas de rendimiento, que permiten distinguir la variabilidad tanto química como física existente en el lote de estudio.",
        title: "Análisis nutricional de alta definición",
        icon: "images",
        GetAmbientationPath: (routeParams, toolFieldId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/ambientations/${toolFieldId}/section-1`;
        },
        GetAmbientationsSection2Path: (routeParams, processId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/ambientations/${routeParams.ambientationId}/section-2/${processId}`;
        },
        GetAmbientationsSection3Path: (routeParams, processId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/ambientations/${routeParams.ambientationId}/section-3/${processId}`;
        },
        GetAmbientationsSection3PathForProducers: (routeParams, toolFieldId, processId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/ambientations/${toolFieldId}/section-3/${processId}`;
        },
        GetSuggestedAmbientationsPath: (routeParams, processId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/ambientations/${routeParams.ambientationId}/suggested-ambientations/${processId}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            if (IsAnUserAdmin()) {
                return {
                    name: this.routeName,
                    params: { producerId, fieldId, ambientationId: toolFieldId },
                };
            }

            const AmbientationServices = (await import("../services/ambientation-records.services")).default;
            const apiResult = await AmbientationServices.GetProcessAsync(toolFieldId);
            if (!apiResult) return null;
            if (apiResult.records.length == 0) return null;
            const processId = apiResult.records[0].IdProcess;
            return {
                name: "AmbientationsSection3",
                params: { producerId, fieldId, ambientationId: toolFieldId, processId },
            };
        },
    },
    MonitoreoDron: {
        id: 8,
        routeName: "DroneMonitoring",
        description:
            "Informe de calidad de siembra, presencia de malezas y ataque tempranos de cogollero en Maíz, son las opciones posibles a realizar mediante Corteva Flight. Consiste en realizar vuelos con Drone, que tras haber sobrevolado el lote y capturado imágenes de alta definición, procesa toda la información recolectada a través de algoritmos con inteligencia artificial para entregar un reporte preciso, rápido y fiable.",
        title: "Monitoreo con Drone (Corteva Flight)",
        icon: "video",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, toolFieldId },
            };
        },
    },
    ServicioFertilizacion: {
        id: 9,
        title: "Prescripciones",
        routeName: "Prescriptions",
        description:
            "Ofrecemos el servicio de fertilización y siembra variable para que puedas optimizar el uso de los recursos e insumos y produzcas de manera sustentable tus cultivos.",
        title: "Prescripciones de Siembra/Fertilización",
        icon: "seedling",
        GetPrescriptionSection1PathFromAmbientationsSection3: (routeParams, prescriptionId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/prescription/${prescriptionId}`;
        },
        GetPrescriptionSection2Path: (routeParams, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/prescription/${routeParams.prescriptionId}/section-2/${reportId}`;
        },
        GetPrescriptionSection2PathForProducers: (routeParams, prescriptionId, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/prescription/${prescriptionId}/section-2/${reportId}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, prescriptionId) {
            if (IsAnUserAdmin()) {
                return {
                    name: this.routeName,
                    params: { producerId, fieldId, prescriptionId },
                };
            }

            const PrescriptionServices = (await import("../services/prescription.services")).default;
            const apiResult = await PrescriptionServices.GetPrescriptionProcessListInfoByToolFieldIdAsync(prescriptionId);
            if (!apiResult) return null;
            if (apiResult.records.length == 0) return null;
            const processId = apiResult.records[0].processId;
            return {
                name: "PrescriptionsSection2",
                params: { producerId, fieldId, prescriptionId, processId },
            };
        },
    },
    AnalisisPostcosecha: {
        id: 10,
        routeName: "PostHarvestAnalysis",
        description: "A partir de los resultados obtenidos en la campaña, elaboramos un informe analizando parámetros productivos y de rentabilidad.",
        title: "Informe de Recomendación",
        icon: "clipboard",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, toolFieldId },
            };
        },
    },
    MonitoreoDelCultivo: {
        id: 11,
        routeName: "CropMonitoring",
        description: "A partir de recorridas mensuales, te comentamos cómo se encuentra tu cultivo.",
        title: "Monitoreo del cultivo",
        icon: "video",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, toolFieldId },
            };
        },
    },
    Penetrometria: {
        id: 12,
        routeName: "Penetrometry",
        description:
            "Evalúa la resistencia mecánica del suelo, característica que interactúa con otras propiedades como: la densidad aparente, la textura, el contenido de humedad y la porosidad.",
        title: "Penetrometría",
        icon: "spoon",
        GetPenetrometrySection2Path: (routeParams, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/penetrometry/${routeParams.penetrometryId}/section-2/${reportId}`;
        },
        GetPenetrometrySection2PathForProducers: (routeParams, penetrometryId, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/penetrometry/${penetrometryId}/section-2/${reportId}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, penetrometryId) {
            if (IsAnUserAdmin()) {
                return {
                    name: this.routeName,
                    params: { producerId, fieldId, penetrometryId },
                };
            }

            const PenetrometryServices = (await import("../services/penetrometry.services")).default;
            const apiResult = await PenetrometryServices.GetPenetrometryProcessListInfoByToolFieldIdAsync(penetrometryId);
            if (!apiResult) return null;
            if (apiResult.records.length == 0) return null;
            const processId = apiResult.records[0].IdProcess;
            return {
                name: "PenetrometrySection2",
                params: { producerId, fieldId, penetrometryId, processId },
            };
        },
    },
    ImagenesSatelitales: {
        id: 13,
        routeName: "SatelitalImages",
        description: "Manejo de Imágenes Satelitales con Sentinel.",
        title: "Imágenes Satelitales",
        icon: "satellite-dish",
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, satelitalImageId) {
            return {
                name: this.routeName,
                params: { producerId, fieldId, satelitalImageId },
            };
        },
    },
    Informes: {
        id: 14,
        routeName: "Reports",
        description: "Informe detallado del lote",
        title: "Informe de Diagnóstico",
        icon: "clipboard",
        GetReportsSection2Path: (routeParams, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/reports/${routeParams.toolReportId}/section-2/${reportId}`;
        },
        GetReportsSection2PathForProducers: (routeParams, toolReportId, reportId) => {
            return `/producers/${routeParams.producerId}/fields/${routeParams.fieldId}/reports/${toolReportId}/section-2/${reportId}`;
        },
        GetRouteToUseInFieldsSectionAsync: async function (producerId, fieldId, toolFieldId) {
            if (IsAnUserAdmin()) {
                return {
                    name: this.routeName,
                    params: { producerId, fieldId, toolReportId: toolFieldId },
                };
            }

            const ReportsServices = (await import("../services/reports-tool.services")).default;
            const apiResult = await ReportsServices.GetReportsProcessListInfoByFieldIdAsync(fieldId);
            if (!apiResult) return null;
            if (apiResult.reports.length == 0) return null;
            const reportId = apiResult.reports[0].id;

            return {
                name: "ReportsSection2",
                params: { producerId, fieldId, toolReportId: toolFieldId, reportId },
            };
        },
    },
    GetHerramientaById(id) {
        return Object.values(this).find((obj) => obj.id == id);
    },
    GetValues() {
        const result = Object.values(this).filter((obj) => obj.id != null);
        return result;
    },
    GetIdHerramientaByRouteName(routeName) {
        const result = Object.values(this).find((obj) => obj.routeName == routeName);
        return result ? result.id : -1;
    },
    GetHerramientaByIds(arrayIds) {
        return Object.values(this).filter((obj) => arrayIds.includes(obj.id));
    },
    GetRecordsPath(idHerramienta, idHerramientaEstablecimiento) {
        const herramienta = this.GetHerramientaById(idHerramienta);
        if (!herramienta) return "";
        return `${herramienta.path}/${idHerramientaEstablecimiento}`;
    },
    GetOlderToolsId() {
        // return [2, 3, 4, 5, 7];
        return [4, 5];
    },
    GetChildRouteNameById(idHerramienta) {
        return `Registros${this.GetHerramientaById(idHerramienta).routeName}`;
    },
};

module.exports.ProcessStates = Object.freeze({
    2: {
        color: "#FF9502",
        description: "Archivos validados, guardando en nube...",
    },
    3: {
        color: "#FFE900",
        description: "Archivos guardados",
    },
    4: {
        color: "#DB00CC",
        description: "Generando ambientaciones",
    },
    6: {
        color: "#05A500",
        description: "Proceso finalizado",
    },
});
