import { UpdateWebsocketCircle } from "../utils/dom.utils";
import { BaseResponse } from "../messages/responses/base.response";
import { ProcessStateUpdatedResponse } from "../messages/responses/process-state-updated.response";
import { AmbientationFilePathUpdatedResponse } from "../messages/responses/ambientation-file-path-updated.response";
import { ComparisionsResultsHasBeenCompletedResponse } from "../messages/responses/comparisions-results-has-been-completed.response.js";
import Action from "../utils/enums.utils";
import { GetUserFromStorage } from "../../utils/user.utils";
import { NotifyOk } from "../../helpers/vue-notifications.helper";
import vuexStore from "../../store/index";
import { ProcessStates } from "../../Auxiliares/Enums";

class WebSocketModel {
    constructor() {
        this.socket = null;
        this.retryCount = 0;
        this.StartLoopToStayWebSocketConnected();
    }

    StartLoopToStayWebSocketConnected() {
        setInterval(() => { this.TryConnectWithServer(); }, 5000);
    }
    TryConnectWithServer() {
        if (this.socket == null || this.socket.readyState == WebSocket.CLOSED) {
            console.log("Intentando conectar con el servidor...");
            this.socket = new WebSocket(process.env.VUE_APP_WS_URL);
            this.socket.addEventListener("open", () => {
                console.log("Conexión establecida!");
                TrySetUserIdInTheServersWebSocket();
            });

            this.socket.addEventListener("message", (event) => {
                this.ReceivedMessage(event.data);
            });

            this.socket.addEventListener("close", (event) => {
                console.log(`Conexión cerrada: ${event.code}`);
                UpdateWebsocketCircle();
            });
        }
    }

    ReceivedMessage(stringifyData) {
        const data = JSON.parse(stringifyData);
        if (data.action == Action.SetUserId) {
            const response = new BaseResponse(data.id, data.action, data.responseCode);
            if (response.Ok()) {
                console.log("UserId enlazado correctamente!");
                UpdateWebsocketCircle(1);
                return;
            }
        }

        if (data.action == Action.ProcessStateUpdated) {
            const response = new ProcessStateUpdatedResponse(data.id, data.action, data.responseCode, data.processId, data.processState);
            if (response.Ok()) {
                //TODO: Ver si disparamos la notificacion aunque no estemos en la pagina de ambientacion
                const { processId, processState } = response;
                const processStateNumber = parseInt(processState);
                NotifyOk(`El proceso id ${processId} ha cambiado de estado a ${processState} (${ProcessStates[processStateNumber].description})`);
                vuexStore.dispatch("ambientationsSection1Store/updateProcessState", { processId, processState });
                //TODO: Hay que generar un nuevo action para los procesos de penetrometria
                vuexStore.dispatch("penetrometrySection1Store/updatePenetrometryProcessState", { processId, processState });
                //TODO: Hay que generar un nuevo action para los procesos de prescripciones
                vuexStore.dispatch("prescriptionSection1Store/updatePrescriptionProcessState", { processId, processState });
                return;
            }
        }

        if (data.action == Action.AmbientationFilePathUpdated) {
            const response = new AmbientationFilePathUpdatedResponse(data.id, data.action, data.responseCode, data.ambientationId, data.filePath, data.filePathRunning);
            if (response.Ok()) {
                //TODO: Ver si disparamos la notificacion aunque no estemos en la pagina de ambientacion
                const { ambientationId, filePath, filePathRunning } = response;
                vuexStore.dispatch("ambientationsSection3Store/setProcessedAmbientationFilePath", { ambientationId, filePath, filePathRunning });
                return;
            }
        }

        if (data.action == Action.ComparisionsResultsHasBeenCompleted) {
            const response = new ComparisionsResultsHasBeenCompletedResponse(data.id, data.action, data.responseCode, data.processId);
            if (response.Ok()) {
                const { processId } = response;
                vuexStore.dispatch("suggestedAmbientationsStore/SetInfoFromServerAsync", { processId });
                return;
            }
        }
    }

    SendMessage(jsonData) {
        if (webSocketModel.socket == null) return;

        if (webSocketModel.socket.readyState == WebSocket.OPEN) {
            webSocketModel.socket.send(JSON.stringify(jsonData));
            return;
        }

        if (webSocketModel.socket.readyState == WebSocket.CONNECTING) {
            this.retryCount++;
            if (this.retryCount > 10) {
                console.log("Se ha superado el limite de intentos de conexion");
                return;
            }
            setTimeout(() => { webSocketModel.SendMessage(jsonData); }, 1000);
            return;
        }
    }
}

const webSocketModel = new WebSocketModel();

const TrySetUserIdInTheServersWebSocket = () => {
    const user = GetUserFromStorage();
    if (user == null) {
        console.log("No hay usuario en el local storage");
        UpdateWebsocketCircle();
        return;
    }

    const data = {
        action: Action.SetUserId,
        userId: user.ID
    };
    webSocketModel.SendMessage(data);
};

export { TrySetUserIdInTheServersWebSocket };
