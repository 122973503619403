//#region LOCAL STORAGE
/**
 Almacena en el Storage el _Json pasado como parametro, con la _Key pasada como parametro.
La misma funcion se encarga de aplicarle el Stringify al _Json.
Retorna Verdadero cuando logra guardar, y Falso cuando no puede guardar. Ademas en este ultimo caso
limpia el storage.
 */
module.exports.GuardarJsonEnStorage = (key, json) => {
    localStorage.setItem(key, JSON.stringify(json));
};

/**
  Retorna un _Json guardado con la _Key pasada como paramtro.
  Si no lo encuentra, devuelve NULO.
  */
module.exports.RecuperarJsonDeStorage = (_Key) => {
    return JSON.parse(localStorage.getItem(_Key));
};

/**
  Retorna un array de _Json guardados con parte del nombre de la Key pasada como paramtro.
  Si no encuentra nada devuelve vacío.
  */
module.exports.RecuperarJsonsDeStorage = (_PalabraConQueArranca) => {
    var _Retorno = [];
    try {
        for (var key in window.localStorage) {
            if (window.localStorage.hasOwnProperty(key)) {
                if (key.includes(_PalabraConQueArranca)) {
                    _Retorno.push(this.RecuperarJsonDeStorage(key));
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
    return _Retorno;
};
/**
 * Retorna NULO si no logra recuperar nada.
 */

module.exports.EliminarJsonDelStorage = (_KeyAEliminar) => {
    try {
        localStorage.removeItem(_KeyAEliminar);
        console.log(_KeyAEliminar + " eliminado del Storage!");
    } catch (e) {
        console.log(e);
    }
};
module.exports.EliminarJsonDelStorage1 = (_PalabraConQueArranca) => {
    try {
        for (var key in window.localStorage) {
            if (window.localStorage.hasOwnProperty(key)) {
                if (key.includes(_PalabraConQueArranca)) {
                    this.EliminarJsonDelStorage(key);
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
};
module.exports.LimpiarStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        console.log(error);
    }
};
/**
  Retorna el tamaño en KB.
  */
module.exports.TamañoUsadoPorElStorage = () => {
    var _Retorno = 0;
    try {
        var allStrings = "";
        for (var key in window.localStorage) {
            //console.log(key);
            if (window.localStorage.hasOwnProperty(key)) {
                allStrings += window.localStorage[key];
            }
        }
        _Retorno = allStrings ? (allStrings.length * 16) / (8 * 1024) : 0;
    } catch (e) {
        console.log(e);
    }
    return _Retorno;
};
//#endregion

//#region CLASES UTILES
module.exports.Fecha = class {
    constructor(_Fecha) {
        if (_Fecha == null) {
            _Fecha = new Date();
        }
        this._Año = _Fecha.getFullYear();
        this._Mes = _Fecha.getMonth() + 1;
        this._Dia = _Fecha.getDate();
        this._Horas = _Fecha.getHours();
        this._Minutos = _Fecha.getMinutes();
        this._Segundos = _Fecha.getSeconds();
        this._FechaYHora = this.ObtenerTexto();
        this._Fecha = this.ObtenerTexto1();
    }
    ObtenerTexto() {
        var _Retorno = "";
        try {
            _Retorno += ("0" + this._Dia).slice(-2);
            _Retorno += "/" + ("0" + this._Mes).slice(-2);
            _Retorno += "/" + this._Año;
            _Retorno += " " + ("0" + this._Horas).slice(-2);
            _Retorno += ":" + ("0" + this._Minutos).slice(-2);
            _Retorno += ":" + ("0" + this._Segundos).slice(-2);
        } catch (error) {
            console.log(error);
        }
        return _Retorno;
    }
    ObtenerTexto1() {
        var _Retorno = "";
        try {
            _Retorno += ("0" + this._Dia).slice(-2);
            _Retorno += "/" + ("0" + this._Mes).slice(-2);
            _Retorno += "/" + this._Año;
        } catch (error) {
            console.log(error);
        }
        return _Retorno;
    }
    Paquete() {
        return (this._Año + "/" + this._Mes + "/" + this._Dia + "/" + this._Horas + "/" + this._Minutos + "/" + this._Segundos);
    }
    SetearDesdePaquete(_Paquete) {
        var _Aux = _Paquete.split("/");
        if (_Aux.length != 6) {
            return;
        }
        this._Año = _Aux[0];
        this._Mes = _Aux[1];
        this._Dia = _Aux[2];
        this._Horas = _Aux[3];
        this._Minutos = _Aux[4];
        this._Segundos = _Aux[5];

        this._FechaYHora = this.ObtenerTexto();
        this._Fecha = this.ObtenerTexto1();
    }
    ObtenerFecha() {
        return new Date(this._Año, this._Mes - 1, this._Dia, this._Horas, this._Minutos, this._Segundos);
    }
};
module.exports.ClaseEjemplo = class {
    constructor() {
        this._Id = 0;
        this._FechaAlta = "";
        this._NombreProductor = "";
        this._NombreEstablecimiento = "";
        this._Localidad = "";
    }
};

module.exports.Marcador = class {
    constructor() {
        this.id = 0;
        this.name = "";
        this.localidad = "";
        this.description = "";
        this.position = null;
    }
};

module.exports.GeoLocation = class {
    constructor({ lat, lon }) {
        this.lat = lat ?? 0;
        this.lng = lon ?? 0;
    }
};
//#endregion

String.prototype.PrimeraLetraMayuscula = function () {
    if (this.length < 1) {
        return this.toString();
    } else {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
};
String.prototype.LimpiarCaracteresInvalidos = function () {
    return this.replace(/([^a-z0-9\s]+)/gi, "-");
};

Array.prototype.UltimosCincoItems = function () {
    return this.slice(Math.max(this.length - 10, 0));
};

String.prototype.ObtenerNumero = function () {
    if (typeof this == "number") return this;
    let _Retorno = this;
    _Retorno = _Retorno.replace(",", ".");
    _Retorno = parseFloat(_Retorno);
    if (isNaN(_Retorno)) { _Retorno = 0; }
    return _Retorno;
};

module.exports.ScrollDirection = class {
    static get Up() {
        return 1;
    }
    static get Down() {
        return 2;
    }
};

module.exports.GetUniqueName = function () {
    return Math.round(Math.random() * 10000 * 10000);
};

module.exports.KeyUser = "UserAgroIdeas";
//TODO:(!) Remover en llamados desde componentes, ya que en estos se puede recurrir al this.user
module.exports.GetUserLogged = () => {
    return this.RecuperarJsonDeStorage(this.KeyUser);
};
