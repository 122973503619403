<template>
  <v-btn class="color-white fw-600" :class="bgColor">
    <router-link class="reset-tag-a color-white d-flex align-center" :to="route">
      <svg-icon type="mdi" :path="mdiMapMarkerRadiusOutline" class="mr-2"></svg-icon>
      Ver Mapa Satelital
    </router-link>
  </v-btn>
</template>

<script>
import { mdiMapMarkerRadiusOutline } from "@mdi/js";
export default {
  props: {
    route: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: "bg-primary"
    }
  },
  data() {
    return {
      mdiMapMarkerRadiusOutline
    };
  },
  name: "BotonVerMapa"
};
</script>

<style lang="scss" scoped>
@import "../css/Componentes.scss";
</style>
