import { Capitalize } from "../../helpers/utilities.helper";
import { AxiosGetAsync } from "../../services/base.services";
import { IsTextInText } from "../../helpers/string.helper";
import { router } from "../../router/NewRouter";

const GetDefaultState = () => {
    return {
        fullList: [],
        filteredList: [],
        textToSearch: ""
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getFilteredList(state) {
            const result = JSON.parse(JSON.stringify(state.filteredList));
            const filteredList = result.filter(prod => IsTextInText(state.textToSearch, prod.capitalizedName) || IsTextInText(state.textToSearch, prod.BusinessName));
            return filteredList.sort((a, b) => `${a.BusinessName}` < `${b.BusinessName}` ? -1 : 1);
        },
        getTextToSearch(state) {
            return state.textToSearch;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, GetDefaultState());
        },
        setFullList(state, fullList) {
            state.fullList = fullList;
            state.filteredList = fullList;
        },
        SetNewSearchText(state, searchText) {
            state.textToSearch = searchText;
        },
        SetUrl(state) {
            const query = {};

            query.searchText = state.textToSearch;

            if (query.searchText != "") query.searchText = state.textToSearch;;

            const currentQuery = router.currentRoute.query;
            if (JSON.stringify(currentQuery) == JSON.stringify(query)) return;

            router.push({ query });
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setFullListAsync({ commit }) {
            const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/producers`);
            if (result.error != null) return false;

            const producers = result.data;
            producers.forEach(productor => {
                productor.capitalizedName = `${Capitalize(productor.Nombre)} ${Capitalize(productor.Apellido)}`;
            });
            producers.sort((a, b) => `${a.capitalizedName}` < `${b.capitalizedName}` ? -1 : 1);
            commit("setFullList", producers);
            return true;
        },
        SetNewSearchText({ commit }, searchText) {
            commit("SetNewSearchText", searchText);
            commit("SetUrl");
        }
    },
};
