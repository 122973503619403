import { AxiosGetAsync } from "./base.services";
import DateHelpers from "../helpers/date.helpers";
const HeatMapServices = () => {};

/**
 * @param {string} dateFrom MM-YYYY
 * @param {string} dateTo MM-YYYY
 */
HeatMapServices.GetHetMapPluviometer = async (dateFrom, dateTo) => {
    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/heat-map/pluviometers/${dateFrom}/${dateTo}`);
    return result.data;
};

HeatMapServices.GetDefaultHetMapPluviometer = async () => {
    const dateFrom = DateHelpers.GetMMYYYYFormatDate(DateHelpers.GetDateXMonthsAgo(1));
    return await HeatMapServices.GetHetMapPluviometer(dateFrom, dateFrom);
};

/**
 * @param {string} month MM-YYYY
 */
HeatMapServices.GetHetMapPhreatimeter = async (mmyyyyDate) => {
    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/heat-map/phreatimeters/records-by-month/${mmyyyyDate}`);
    return result.data;
};

HeatMapServices.GetDefaultHetMapPhreatimeter = async () => {
    const dateFrom = DateHelpers.GetMMYYYYFormatDate(DateHelpers.GetDateXMonthsAgo(2));
    return await HeatMapServices.GetHetMapPhreatimeter(dateFrom);
};

export default HeatMapServices;
