<template>
    <v-btn class="color-white fw-600" :class="`${bgColor} ${fullWidthForMobile ? 'w-mobile-100' : ''}`" @click="OnClick">
        <svg-icon type="mdi" :path="mdiPlusCircleOutline"></svg-icon>
        <span :class="{ 'only-desktop': reducedForMobile, 'pl-2': reducedForMobile }">
            <slot></slot>
        </span>
    </v-btn>
</template>

<script>
import { mdiPlusCircleOutline } from "@mdi/js";
export default {
    props: {
        route: {
            type: String,
            default: "",
        },
        bgColor: {
            type: String,
            default: "bg-primary"
        },
        reducedForMobile: {
            type: Boolean,
            default: false
        },
        fullWidthForMobile: {
            type: Boolean,
            default: false
        },
        OnClick: {
            type: Function,
            default: () => { }
        },
    },
    data() {
        return {
            mdiPlusCircleOutline
        };
    }
};
</script>

<style lang="scss" scoped></style>
