<template>
    <div class="container-section1" :class="{'bg-login': $route.name == 'Login'}">
        <img src="/img/login1.webp" alt="" class="img-section1">
        <img src="/img/login2.webp" alt="" class="img-section2">
    </div>
</template>

<script>
export default {
    mounted(){
        // console.log(this.$route.name == 'Login');
    }
};
</script>

<style lang="scss" scoped>

    .container-section1{
        z-index:-1;
        position:fixed;
        bottom: 0px;
        left:0px;
        width:100vw;
        height:100%;
        & .img-section1, .img-section2{
            width: 200px;
            position: absolute;
            opacity: 0.4;
        }
        & .img-section1{
            left:0px;
            bottom: 60px;
        }
        & .img-section2{
            right:0px;
            top: 60px;
        }
        &.bg-login{
            background: url("~@/assets/slide-2.webp") no-repeat;
            background-size: cover;
            background-position: center;
            & .img-section1{
                bottom: 10px;
            }
            & .img-section2{
                top: 10px;
            }
        }
        @media (min-width: 800px) {
            /* desktop */
            & .img-section1{
                bottom: 50px;
            }
            & .img-section2{
                top: 70px;
            }
            & .img-section1, .img-section2{
                width: 350px;
            }
        }
    }
</style>
