import PenetrometryServices from "../../../services/penetrometry.services";

export default {
    namespaced: true,
    state: {
        ambientationsInfo: null
    },
    getters: {
        getPenetrometryAmbientations(state) {
            if (state.ambientationsInfo == null) return null;

            return state.ambientationsInfo;
        },
    },
    mutations: {
        setPenetrometryAmbientationsInfo(state, value) {
            if (value == null) return;

            state.ambientationsInfo = value;
        }
    },
    actions: {
        async setPenetrometryAmbientationsInfo({ commit }, { toolFieldId, processId }) {
            const data = await PenetrometryServices.GetPenetrometryFullProcessInfoAsync(toolFieldId, processId);
            if (!data) return;

            commit("setPenetrometryAmbientationsInfo", data);
        },
    }
};

