import ExcelJS from "exceljs";

const GenerateExcelAsync = async (headers, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Datos");

    // Agregar encabezados
    worksheet.columns = headers.map(header => ({ header, key: header, width: header.length + 2 }));

    // Agregar datos
    data.forEach(row => {
        worksheet.addRow(row);
    });

    // Agregar filtros a los encabezados
    const firstRow = worksheet.getRow(1);
    if (firstRow.lastCell) {
        worksheet.autoFilter = {
            from: "A1",
            to: firstRow.lastCell.address
        };
    }

    // Ajustar el ancho de las columnas
    worksheet.columns.forEach(column => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
            maxLength = Math.max(maxLength, cell.value ? cell.value.toString().length : 0);
        });
        column.width = maxLength + 2;
    });

    // Generar el archivo con el nombre "Vencimientos de herramientas por lote por productor.xlsx"
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Vencimientos de herramientas por lote por productor.xlsx";
    link.click();
    URL.revokeObjectURL(url);

};

export { GenerateExcelAsync };
