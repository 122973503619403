import { AxiosGetAsync, AxiosPostAsync, AxiosDeleteAsync } from "./base.services";
const PenetrometryServices = () => {};
/**
 *  @param { Number } toolFieldId
 */
PenetrometryServices.GetPenetrometryProcessListInfoByToolFieldIdAsync = async (toolFieldId) => {
    if (!Number.isInteger(toolFieldId)) return null;

    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/penetrometry/processes-by-field/${toolFieldId}`);
    if (result.data.records.length > 0) result.data.records.sort((a, b) => b.IdProcess - a.IdProcess);
    return result.data;
};
/**
 *  @param { Number } toolFieldId
 * @param { Number } processId
 */
PenetrometryServices.GetPenetrometryFullProcessInfoAsync = async (toolFieldId, processId) => {
    if (!Number.isInteger(toolFieldId) && !Number.isInteger(processId)) return null;

    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/penetrometry/${toolFieldId}/${processId}`);
    return result.data;
};

/**
 * @param { Number } fieldId
 * @param { FormData } formData
 */
PenetrometryServices.UploadPenetrometryFileAsync = async (fieldId, formData) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/penetrometry/file/${fieldId}`;
    const result = await AxiosPostAsync(url, formData);
    return result;
};
/**
 * @param { Number } processId
 */
PenetrometryServices.DeletePenetrometryProcessByIdAsync = async (processId) => {
    const result = await AxiosDeleteAsync(`${process.env.VUE_APP_API_URL}/v1/penetrometry/process/${processId}`);
    return result == null;
};

export default PenetrometryServices;
