<template>
    <router-link :class="clases" :to="route">
        <font-awesome-icon v-if="icon != ''" :icon="icon" size="3x" :style="{ color: 'white' }"
            class="IconoOpcionMenu vAliMid" />
        <div class="text-overflow" :class="{ 'fixed-margin': icon == '' }">
            <slot></slot>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: ""
        },
        route: {
            type: String,
            required: false,
            default: ""
        },
        tieneBordeInferior: {
            type: Boolean,
            required: false
        },
        tieneBordeSuperior: {
            type: Boolean,
            required: false
        }
    },
    name: "GUILink",
    data() {
        return {
            clases: "OpcionMenu"
        };
    },
    mounted() {
        this.clases += this.tieneBordeInferior ? " BordeInferior" : "";
        this.clases += this.tieneBordeSuperior ? " BordeSuperior" : "";
    }
};
</script>

<style scoped>
.fixed-margin {
    margin-left: 30px;
}

.OpcionMenu {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: white;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4rem;
    align-items: center;
}

.OpcionMenu:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.IconoOpcionMenu {
    width: 24px;
    height: 24px;
    margin: 0 24px;
}

.BordeSuperior {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.BordeInferior {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
</style>
