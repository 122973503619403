import Vue from "vue";
import VueRouter from "vue-router";
import { Herramientas } from "../Auxiliares/Enums";
import indexStore from "../store/index";
import { UserRoleEnum } from "../utils/general.utils";
import { NotifyError } from "../helpers/vue-notifications.helper";

Vue.use(VueRouter);

const pathToFields = "/producers/:producerId/fields/:fieldId";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Home",
            component: () => import("../views/Home.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
        {
            path: "/index.html",
            name: "Home1",
            component: () => import("../views/Home.vue"),
        },
        {
            path: "/login",
            name: "Login",
            component: () => import("../views/Login.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
        {
            path: "/push-registration",
            name: "Notification",
            component: () => import("../views/Admin/Notification.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
        {
            path: "/producers",
            name: "Producers",
            component: () => import("../views/Admin/Producers.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: false,
            },
        },
        {
            path: "/producers/:producerId/fields",
            name: "Fields",
            component: () => import("../views/Secciones/Fields/index.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
        {
            path: pathToFields + "/pluviometers/:pluviometerId",
            name: "Pluviometers",
            component: () => import("../views/Secciones/Pluviometers/index.vue"),
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/phreatimeters/:phreatimeterId",
            name: "Freatimeters",
            component: () => import("../views/Secciones/Freatimeters/index.vue"),
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/ambientations/:ambientationId/section-1",
            name: "AmbientationsSection1",
            component: () => import("../views/Secciones/Ambientations/section-1/index.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/ambientations/:ambientationId/section-2/:processId",
            name: "AmbientationsSection2",
            component: () => import("../views/Secciones/Ambientations/section-2/index.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/ambientations/:ambientationId/section-3/:processId",
            name: "AmbientationsSection3",
            component: () => import("../views/Secciones/Ambientations/section-3/index.vue"),
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/ambientations/:ambientationId/suggested-ambientations/:processId",
            name: "SuggestedAmbientations",
            component: () => import("../views/Secciones/Ambientations/suggested-ambientations/index.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/satelital-images/:satelitalImageId",
            name: "SatelitalImages",
            component: () => import("../views/Secciones/SatelitalImages/index.vue"),
            props: { herramienta: Herramientas.ImagenesSatelitales },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/prescription/:prescriptionId",
            name: "Prescriptions",
            component: () => import("../views/Secciones/Prescription/Section-1/index.vue"),
            props: { herramienta: Herramientas.ServicioFertilizacion },
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/prescription/:prescriptionId/section-2/:processId",
            name: "PrescriptionsSection2",
            component: () => import("../views/Secciones/Prescription/Section-2/index.vue"),
            props: { herramienta: Herramientas.ServicioFertilizacion },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/penetrometry/:penetrometryId",
            name: "Penetrometry",
            component: () => import("../views/Secciones/Penetrometry/Section-1/index.vue"),
            props: { herramienta: Herramientas.Penetrometria },
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/penetrometry/:penetrometryId/section-2/:processId",
            name: "PenetrometrySection2",
            component: () => import("../views/Secciones/Penetrometry/Section-2/index.vue"),
            props: { herramienta: Herramientas.Penetrometria },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: "/Extended-Forecast",
            name: "ExtendedForecast",
            component: () => import("../views/Secciones/ExtendedForecast.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: false,
            },
        },
        {
            path: pathToFields + "/post-harvest-analysis/:toolFieldId",
            name: "PostHarvestAnalysis",
            component: () => import("../views/Secciones/Records/NewToolsRecordsManager.vue"),
            props: { herramienta: Herramientas.AnalisisPostcosecha },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/useful-water/:toolFieldId",
            name: "UsefulWater",
            component: () => import("../views/Secciones/Records/NewToolsRecordsManager.vue"),
            props: { herramienta: Herramientas.Aguas },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/crop-monitoring/:toolFieldId",
            name: "CropMonitoring",
            component: () => import("../views/Secciones/Records/NewToolsRecordsManager.vue"),
            props: { herramienta: Herramientas.MonitoreoDelCultivo },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/Satelitals/:toolFieldId",
            name: "Satelitals",
            component: () => import("../views/Secciones/Records/NewToolsRecordsManager.vue"),
            props: { herramienta: Herramientas.Satelitales },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/drone-monitoring/:toolFieldId",
            name: "DroneMonitoring",
            component: () => import("../views/Secciones/Records/NewToolsRecordsManager.vue"),
            props: { herramienta: Herramientas.MonitoreoDron },
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/reports/:toolReportId",
            name: "Reports",
            component: () => import("../views/Secciones/Reports/Section-1/index.vue"),
            props: { herramienta: Herramientas.Informes },
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: true,
            },
        },
        {
            path: pathToFields + "/reports/:toolReportId/section-2/:reportId",
            name: "ReportsSection2",
            component: () => import("../views/Secciones/Reports/Section-2/index.vue"),
            meta: {
                hasBreadCrumb: true,
            },
        },
        {
            path: "/vermapa",
            name: "ShowMap",
            component: () => import("../views/Secciones/ShowMap.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: false,
            },
        },
        {
            path: "/pluviometers-list",
            name: "PluviometersList",
            component: () => import("../views/Secciones/Pluviometers/fast-loading-records/index.vue"),
            meta: {
                rolesAllowed: [UserRoleEnum.ADMIN],
                hasBreadCrumb: false,
            },
        },
        {
            path: "/phreatimeters-list",
            name: "PhreatimetersList",
            component: () => import("../views/Secciones/Freatimeters/fast-loading-records/index.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
        {
            path: "/poc-g",
            name: "POCGeolocation",
            component: () => import("../components/globals/POCGeolocation.vue"),
            meta: {
                hasBreadCrumb: false,
            },
        },
    ],
});

const GetRoute = (name) => {
    return router.options.routes.find((route) => route.name === name);
};

const GetPathToFields = (producerId) => {
    return GetRoute("Fields").path.replace(":producerId", producerId);
};

const GetPathToField = (producerId, fieldId) => {
    const pathToFields = GetPathToFields(producerId);
    return `${pathToFields}?fieldId=${fieldId}`;
};

const GetPathToAmbientationsSection1 = (producerId, fieldId, ambientationId) => {
    return GetRoute("AmbientationsSection1").path.replace(":producerId", producerId).replace(":fieldId", fieldId).replace(":ambientationId", ambientationId);
};

const GetPathToPenetrometrySection1 = (producerId, fieldId, penetrometryId) => {
    return GetRoute("Penetrometry").path.replace(":producerId", producerId).replace(":fieldId", fieldId).replace(":penetrometryId", penetrometryId);
};

const GetPathPrescriptionsSection1 = (producerId, fieldId, prescriptionId) => {
    return GetRoute("Prescriptions").path.replace(":producerId", producerId).replace(":fieldId", fieldId).replace(":prescriptionId", prescriptionId);
};

const GetReportsSection1Path = (producerId, fieldId, toolReportId) => {
    return GetRoute("Reports").path.replace(":producerId", producerId).replace(":fieldId", fieldId).replace(":toolReportId", toolReportId);
};

let pendingNotificationMessage = null;
router.beforeEach((to, from, next) => {
    if (from.path != to.path) {
        // Verifica si la ruta cambió
        indexStore.dispatch("ResetAllStates");
        indexStore.dispatch("guiStore/SetCurrentRouteName", to.name);
    }

    if (!_UserAllowed(to)) {
        pendingNotificationMessage = "No tienes permisos para acceder a esta sección";
        return router.push({ name: "Login" });
    }

    next();
});

const _UserAllowed = (to) => {
    const rolesAllowed = to.meta.rolesAllowed ?? [];
    if (rolesAllowed.length === 0) return true;

    const userLogged = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE));
    if (userLogged == null) return false;

    return rolesAllowed.includes(userLogged.RolUsuario ?? 0);
};

router.afterEach(() => {
    // Enviamos el mensaje de error en esta instancia ya que en el beforeEach la app aún no se ha instanciado completamente
    if (pendingNotificationMessage) {
        NotifyError(pendingNotificationMessage);
        pendingNotificationMessage = null;
    }
});

export {
    router,
    GetRoute,
    GetPathToFields,
    GetPathToAmbientationsSection1,
    GetPathToPenetrometrySection1,
    GetPathPrescriptionsSection1,
    GetReportsSection1Path,
    GetPathToField,
};
