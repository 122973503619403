/**
 * @param {string} key It can be any string, such as a URL of some endpoint.
 * @returns {Object | null} Returns the object from the local storage if it exists, otherwise returns null.
 */
export const TryGetObjectFromLocalStorage = (key) => {
    const cachedObject = localStorage.getItem(key);
    if (cachedObject) return JSON.parse(cachedObject);
    return null;
};

/**
 * @param {string} key It can be any string, such as a URL of some endpoint.
 * @param {Object} object The object to be saved in the local storage.
 * @returns {boolean} Returns true if the object was saved successfully, otherwise returns false.
 */
export const TrySaveObjectToLocalStorage = (key, object) => {
    if (!key || !object) return false;

    localStorage.setItem(key, JSON.stringify(object));

    return true;
};
