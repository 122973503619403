<template>
    <button v-if="showGoTop" @click="goTop" class="go-top-btn" title="Go Top">
        <svg-icon type="mdi" :path="mdiArrowUpBold" size="24" />
    </button>
</template>

<script>
import { mdiArrowUpBold } from "@mdi/js";
export default {
    data() {
        return {
            showGoTop: false,
            mdiArrowUpBold
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        goTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        handleScroll() {
            this.showGoTop = window.scrollY > 50;
        },
    },
    watch: {
        // Agregar un watcher para window.scrollY
        "$data": {
            handler() {
                this.handleScroll();
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.go-top-btn {
    position: fixed;
    z-index: 10;
    bottom: 65px;
    right: 5px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px #000000ff;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    @media (min-width: 799px) {
        bottom: 5px;
    }
}

.go-top-btn:hover {
    opacity: 1;
}
</style>
