
/**
 * Retorna el input si todo está ok, sino devuelve la fecha pasada como parámetro.
 */
String.prototype.ValidDate = function () {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;

        if (this.match(regex) === null) {
          return false;
        }

        const [day, month, year] = this.split("/");

        // 👇️ format Date string as `yyyy-mm-dd`
        const isoFormattedStr = `${year}-${month}-${day}`;

        const date = new Date(isoFormattedStr);

        const timestamp = date.getTime();

        if (typeof timestamp !== "number" || Number.isNaN(timestamp))
            return false;

        return date.toISOString().startsWith(isoFormattedStr);
};
String.prototype.TryGetDateFromDDMMYYYY = function() {
    try {
        if(this.ValidDate()){
            const splitedDate = this.split("/").map(x => Number(x));
            const now = new Date();
            return new Date(splitedDate[2], splitedDate[1] - 1, splitedDate[0], now.getHours(), now.getMinutes(), now.getSeconds());
        }
    } catch (error) {
        console.log(error);
    }
    return new Date();
};
String.prototype.GetFirstXChars = function(length) {
    return this.slice(0, length);
};
