const getDefaultState = () => {
    return {
        dataFromServer: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getSafeData(state) {
            let result = state.dataFromServer;
            if (result == null) return null;

            result = JSON.parse(JSON.stringify(result));

            return result;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setDataFromServer(state, { result }) {
            if (result != null) {
                state.dataFromServer = result;
            }
        },
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        // eslint-disable-next-line no-unused-vars
        setDataFromServer({ commit }, { result, loteId }) {
            if (result == null) return;

            commit("setDataFromServer", { result, loteId });
        },
    },
};
