import { router } from "../../../router/NewRouter";
import AmbientationServices from "../../../services/ambientation-records.services";

const GetDefaultState = () => {
    return {
        results: [],
        locationInfo: {},
        layersThatCategorizeInversely: [],
        creationDate: null,
        processId: null,
        fieldName: "",
        producerName: "",
        producerId: null,
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getState(state) {
            return state;
        }
    },
    mutations: {
        setInfoFromServer(state, data) {
            if (data == null) return;

            state.results = data.results;
            state.layersThatCategorizeInversely = data.layersThatCategorizeInversely;
            state.creationDate = data.creationDate;
            state.processId = data.processId;
            state.fieldName = data.fieldName;
            state.producerName = data.producerName;
            state.locationInfo = data.locationInfo;
        },
        ResetState(state) {
            Object.assign(state, GetDefaultState());
        },
    },
    actions: {
        async SetInfoFromServerAsync({ commit }, { processId }) {
            const paramProcessId = router.currentRoute.params.processId;
            const tooldFieldId = router.currentRoute.params.ambientationId;
            if (processId != paramProcessId) return false;

            const result = await AmbientationServices.GetSuggestedAmbientationsAsync(tooldFieldId, paramProcessId);
            if (!result) return false;

            commit("setInfoFromServer", result);

            return true;
        },
        ResetState({ commit }) {
            commit("ResetState");
        },
    }
};

