class UserPreferences { }

UserPreferences.getMapOpacity = () => {
    return localStorage.getItem("mapOpacity") || 0.5;
};

UserPreferences.setMapOpacity = (value) => {
    localStorage.setItem("mapOpacity", value);
};

UserPreferences.getMapZoom = () => {
    return Number(localStorage.getItem("mapZoom")) || 15;
};

UserPreferences.setMapZoom = (zoomGroupName, value) => {
    localStorage.setItem(zoomGroupName, value);
};

export default UserPreferences;
