import { router } from "../../router/NewRouter";
import { AxiosGetAsync } from "../../services/base.services";
import { IsNullOrEmpty } from "../../helpers/string.helper";
import { NameStandardizer } from "../../utils/common.utils";
import { IsTextInText } from "@/helpers/string.helper";

// Dejamos esta constante fuera del state para que no genere bucles con referencias circulares.
const markersAndInfoWindow = {};
let GoogleMap = null;

const GetDefaultState = () => {
    return {
        apiResult: null,
        fullList: [],
        filteredFields: [],
        parentsOptions: [],
        fieldsOptions: [],
        toolsOptions: [],
        fieldToEdit: null,
        searchQuery: "",
    };
};

const GetUniqueParentsOptions = (fullList) => {
    const parentsOptions = fullList.map(field => {
        return {
            name: field.ParentField,
            id: field.ParentField,
            checked: false,
        };
    });

    return NameStandardizer.RemoveDuplicatesByName(parentsOptions, "name");
};

const GetFilteredFields = (fullList, searchQuery) => {
    if (IsNullOrEmpty(searchQuery)) return fullList;
    return fullList.filter(field => IsTextInText(searchQuery, field.ParentField) || IsTextInText(searchQuery, field.Nombre));
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getFullList(state) {
            const result = GetFilteredFields(JSON.parse(JSON.stringify(state.fullList)), state.searchQuery);
            return result.sort((fieldA, fieldB) => `${fieldA.Nombre}` < `${fieldB.Nombre}` ? -1 : 1);
        },
        getParentsOptions(state) {
            return state.parentsOptions;
        },
        getFieldsOptions(state) {
            return state.fieldsOptions;
        },
        getToolsOptions(state) {
            return state.toolsOptions;
        },
        getFieldToEdit(state) {
            return state.fieldToEdit;
        },
        getFieldById: (state) => (fieldId) => {
            return state.fullList.find(field => field.ID == fieldId);
        },
        getSelectedParentName(state) {
            return state.parentsOptions.find(parent => parent.checked)?.name;
        },
        getSelectedField(state) {
            const selectedField = state.fieldsOptions.find(field => field.checked);
            if (selectedField == null) return null;

            return state.fullList.find(field => field.ID == selectedField.id);
        },
        getParentsOptionsFromFilteredFields(state) {
            return GetUniqueParentsOptions(state.filteredFields);
        },
        getGoogleMap() {
            return GoogleMap;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, GetDefaultState());
        },
        SetApiResult(state, apiResult) {
            state.apiResult = apiResult;
        },
        SetFullList(state, fullList) {
            fullList = fullList.filter(x => x.Latitud != "" && x.Longitud != "");
            state.fullList = fullList;
        },
        SetParentsOptions(state, parentsOptions) {
            if (parentsOptions.length == 1) parentsOptions[0].checked = true;

            state.parentsOptions = parentsOptions;
            state.fieldsOptions = [];
            state.toolsOptions = [];

            const selectedParent = parentsOptions.find(parent => parent.checked);
            if (selectedParent == null) return;

            const availableFields = NameStandardizer.FilterDuplicateNames(state.fullList, selectedParent.name, "ParentField", "Nombre");

            const fieldsOptions = availableFields.map(field => {
                return {
                    name: field.Nombre,
                    id: field.ID,
                    checked: false,
                };
            });

            if (fieldsOptions.length == 1) fieldsOptions[0].checked = true;

            state.fieldsOptions = fieldsOptions;
        },
        SetFieldsOptions(state, fieldsOptions) {
            state.fieldsOptions = fieldsOptions;
        },
        SetToolsOptions(state, toolsOptions) {
            state.toolsOptions = toolsOptions;
        },
        SetFieldToEdit(state, fieldToEdit) {
            state.fieldToEdit = fieldToEdit;
        },
        SetUrl(_, { fieldId }) {
            if ((typeof fieldId) == "number") fieldId = fieldId.toString();

            const query = {};
            if (!IsNullOrEmpty(fieldId)) query.fieldId = fieldId;

            const currentQuery = router.currentRoute.query;
            if (JSON.stringify(currentQuery) == JSON.stringify(query)) return;

            router.push({ query });
        },
        SetSearchQuery(state, query) {
            state.searchQuery = query;
        },
        ShowMarkersAndInfoWindows(state) {
            //Oculto todos los markers e infoWindows
            for (const field of state.fullList) {
                const marker = markersAndInfoWindow[field.ID]?.marker;
                const infoWindow = markersAndInfoWindow[field.ID]?.infoWindow;
                marker.setMap(null);
                infoWindow.close();
            }

            //Luego muestro los que coincidan con la búsqueda
            const filteredFields = GetFilteredFields(state.fullList, state.searchQuery);
            for (const field of filteredFields) {
                const marker = markersAndInfoWindow[field.ID]?.marker;
                const infoWindow = markersAndInfoWindow[field.ID]?.infoWindow;

                marker.setMap(GoogleMap);
                infoWindow.open(GoogleMap, marker);
            }
        },
        SetFilteredFields(state) {
            state.filteredFields = GetFilteredFields(state.fullList, state.searchQuery);
        },
        AddMarkerAndInfoWindow(_, { marker, infoWindow, field }) {
            markersAndInfoWindow[field.ID] = { marker, infoWindow, field };
        },
        SetGoogleMap(_, map) {
            GoogleMap = map;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async GetInitialDataFromApiAsync({ commit, getters }) {
            const producerId = router.currentRoute.params.producerId;
            if (producerId == null) return false;

            const url = `${process.env.VUE_APP_API_URL}/v1/fields/${producerId}`;
            const apiResult = await AxiosGetAsync(url);
            if (apiResult.error != null) return false;

            commit("SetApiResult", apiResult.data);
            document.title = `Maizplus - Lotes de ${apiResult.data.businessName}`;

            const fields = apiResult.data.fields;
            commit("SetFullList", fields);
            commit("SetFilteredFields", "");
            const originalParentOptions = getters.getParentsOptionsFromFilteredFields;
            commit("SetParentsOptions", originalParentOptions);
        },
        SetFiltersFromUrl({ dispatch, getters }) {
            const fieldId = router.currentRoute.query.fieldId;
            if (IsNullOrEmpty(fieldId)) return;

            const field = getters.getFieldById(fieldId);
            if (!field) return;

            dispatch("SetSelectedField", field);
        },
        SetParentsOptions({ commit, getters }, parentsOptions) {
            commit("SetParentsOptions", parentsOptions);

            const fieldId = getters.getSelectedField?.ID;
            if (fieldId == null) return;

            commit("SetUrl", { fieldId });
        },
        SetFieldsOptions({ commit, getters }, fieldsOptions) {
            commit("SetFieldsOptions", fieldsOptions);

            const fieldId = getters.getSelectedField?.ID;

            commit("SetUrl", { fieldId });
        },
        SetToolsOptions({ commit }, toolsOptions) {
            commit("SetToolsOptions", toolsOptions);
        },
        SetFieldToEdit({ commit }, fieldToEdit) {
            commit("SetFieldToEdit", fieldToEdit);
        },
        SetSelectedParent({ state, dispatch }, parentFieldName) {
            const parentsOptions = state.parentsOptions.map(option => {
                return {
                    name: option.name,
                    id: option.id,
                    checked: option.name.toLowerCase() == parentFieldName?.toLowerCase(),
                };
            });

            dispatch("SetParentsOptions", parentsOptions);
        },
        SetSelectedField({ state, dispatch, getters }, field) {
            if (field == null) return dispatch("SetSelectedParent", null);

            document.title = `Maizplus - Lotes de ${state.apiResult.businessName} - ${field.Nombre}`;

            if (getters.getSelectedParentName != field.ParentField) dispatch("SetSelectedParent", field.ParentField);

            if (state.fieldsOptions.length == 0) dispatch("SetSelectedParent", field.ParentField);

            const fieldsOptions = state.fieldsOptions.map(fieldOption => {
                return {
                    name: fieldOption.name,
                    id: fieldOption.id,
                    checked: fieldOption.id == field.ID,
                };
            });
            dispatch("SetFieldsOptions", fieldsOptions);
        },
        SetSearchQuery({ commit, getters }, query) {
            commit("SetSearchQuery", query);
            commit("ShowMarkersAndInfoWindows");

            const originalParentOptions = getters.getParentsOptionsFromFilteredFields;
            commit("SetParentsOptions", originalParentOptions);
        },
        AddMarkerAndInfoWindow({ commit }, { marker, infoWindow, field }) {
            commit("AddMarkerAndInfoWindow", { marker, infoWindow, field });
        },
        SetGoogleMap({ commit }, map) {
            commit("SetGoogleMap", map);
        },
        ShowMarkersAndInfoWindows({ commit }) {
            commit("ShowMarkersAndInfoWindows");
        },
        GetMarkerAndInfoWindow(_, fieldId) {
            return markersAndInfoWindow[fieldId];
        },

    },
};
