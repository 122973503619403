import Vue from "vue";
import Vuex from "vuex";
import userStore from "./modules/user.store";
import login from "./modules/login.store";
import producers from "./modules/producers.store";
import pluviometro from "./modules/pluviometro.store";
import freatimetro from "./modules/freatimetro.store";
import herramientasByLote from "./modules/herramientas-by-lote.store";
import userPreferences from "./modules/user-preferences.store";
import ambientationsSection1Store from "./modules/ambientations/section-1.store";
import ambientationsSection3Store from "./modules/ambientations/section-3.store";
import penetrometrySection1Store from "./modules/penetrometry/section-1.store";
import penetrometrySection2Store from "./modules/penetrometry/section-2.store";
import prescriptionSection1Store from "./modules/prescription/section-1.store";
import prescriptionSection2Store from "./modules/prescription/section-2.store";
import breadcrumbStore from "./modules/breadcrumb.store";
import suggestedAmbientationsStore from "./modules/ambientations/suggested-ambientations.store";
import processingDialogStore from "./modules/processing-dialog.store";
import heatMapStore from "./modules/heat-map.store";
import showMapStore from "./modules/show-map.store";
import satelitalImagesStore from "./modules/satelital-images.store";
import satelitalMapStore from "./modules/satelital-map.store";
import generalsStore from "./modules/generals.store";
import reportsSection1 from "./modules/reports/section-1.store";
import reportsSection2 from "./modules/reports/section-2.store";
import fieldsStore from "./modules/fields.store";
import guiStore from "./modules/gui-store";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        publicPage: false,
        online: navigator.onLine,
        hideModalAndFooter: false,
    },
    getters: {
        hideModalAndFooter: (state) => state.hideModalAndFooter,
    },
    mutations: {
        setPublicPage(state, componentName) {
            const publicPagesNames = ["Home", "ShowMap", "Login"];
            state.publicPage = publicPagesNames.includes(componentName);
        },
        setOnline(state, value) {
            state.online = value;
        },
        setHideModalAndFooter(state, value) {
            state.hideModalAndFooter = value;
        },
    },
    actions: {
        setPublicPage({ commit }, componentName) {
            commit("setPublicPage", componentName);
        },
        setOnline({ commit }, value) {
            commit("setOnline", value);
        },
        setHideModalAndFooter({ commit }, value) {
            commit("setHideModalAndFooter", value);
        },
        async ResetAllStates({ }) {
            const actionNameToUse = "ResetState";
            const modules = Object.keys(this._modulesNamespaceMap);

            modules.forEach((moduleNamespace) => {
                const namespacedAction = moduleNamespace + actionNameToUse;
                if (this._actions[namespacedAction]) {
                    this.dispatch(namespacedAction);
                }
            });
        },
    },
    modules: {
        userStore,
        login,
        producers,
        pluviometro,
        freatimetro,
        herramientasByLote,
        userPreferences,
        ambientationsSection1Store,
        ambientationsSection3Store,
        penetrometrySection1Store,
        penetrometrySection2Store,
        breadcrumbStore,
        prescriptionSection1Store,
        prescriptionSection2Store,
        suggestedAmbientationsStore,
        processingDialogStore,
        heatMapStore,
        satelitalImagesStore,
        showMapStore,
        satelitalMapStore,
        generalsStore,
        reportsSection1,
        reportsSection2,
        fieldsStore,
        guiStore,
    },
});
