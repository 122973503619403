<template>
    <section class="h-100 p-relative my-date-picker">
        <div v-if="visible" class="my-background" @click.self="visible = false">
            <div class="my-calendar-container">
                <v-date-picker v-model="selectedDate" color="green" />
            </div>
        </div>

        <v-text-field class="w-100 h-100 mt-0" :value="selectedDateFormatted" @click.self="visible = true"
            :label="placeholder">
        </v-text-field>
    </section>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { GetDDMMYYYY } from "../../helpers/date.helpers";

export default {
    components: {
        "v-date-picker": DatePicker
    },
    props: {
        value: {
            type: Date,
            default: null
        },
        placeholder: {
            type: String,
            default: "Fecha"
        }
    },
    data() {
        return {
            visible: false,
            selectedDate: null,
        };
    },
    mounted() {
        if (this.value != null) this.selectedDate = this.value;
    },
    computed: {
        selectedDateFormatted() {
            if (this.value == null) return "";

            return GetDDMMYYYY(this.value);
        }
    },
    watch: {
        selectedDate() {
            this.visible = false;
            this.$emit("on-change", this.selectedDate);
        },
    }

};
</script>

<style scoped lang="scss">
.my-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #00000040;
    z-index: 999;
}

.my-calendar-container {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.v-text-field {
    color: #495057;
}
</style>
