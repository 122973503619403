<template>
    <v-container class="pb-0 pt-0 text-left breadcrumb-container" v-if="getBreadcrumbItems.length > 0">
        <span class="breadcrumb">
            <BreadcrumbItem v-for="(item, index) in getBreadcrumbItems" :key="index" :breadcrumbItem="item" />
        </span>
    </v-container>
</template>

<script>
import BreadcrumbItem from "./BreadcrumbItem.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        BreadcrumbItem,
    },
    computed: {
        ...mapGetters("breadcrumbStore", ["getBreadcrumbItems"]),
    },
};
</script>

<style scoped lang="scss">
.breadcrumb {
    text-align: left;
}

@media (max-width:800px) {
    .breadcrumb-container {
        display: none;
    }
}
</style>
