<template>
    <div v-if="visible">
        <div :class="{'containerLoader': message!=''}">
            <div>
                <div class="spinner" :class="{ 'spinnerVerde': colorVerde}" :style="`width: ${size}px; height: ${size}px`"></div>
            </div>
            <div v-if="message!=''">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        visible: {
            type: Boolean,
            default: true
        },
        size: {
            type: Number,
            default: 36
        },
        colorVerde: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ""
        }
    },
};
</script>

<style scoped lang="scss">
.containerLoader{
    font-size: 1rem;
    width:fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
}
div{
    display: grid;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-left-color: #fff;
    box-sizing: border-box;

    animation: spin 1s ease infinite;
}
.spinnerVerde {
    border-left-color: $primary;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
