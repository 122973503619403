<template>
    <v-dialog :value="!existsRecords" persistent max-width="600px">
        <v-card class="pb-4">
            <v-card-title class="headline title text-uppercase font-weight-bold bg-gradient">Sin conexión</v-card-title>
            <v-card-text class="text-subtitle-1 mt-4">
                No se pudo recuperar información de la sección.
            </v-card-text>
            <v-card-actions class="custom-card-actions">
                <v-btn class="outlined-secondary-button" text @click="GoBack">
                    <svg-icon type="mdi" :path="mdiArrowLeft"></svg-icon>
                    Volver
                </v-btn>
                <v-btn class="outlined-info-button" text @click="GoHome">
                    <svg-icon type="mdi" :path="mdiHome"></svg-icon>
                    Inicio
                </v-btn>
                <v-btn class="outlined-primary-button" text @click="Reload">
                    <svg-icon type="mdi" :path="mdiRefresh"></svg-icon>
                    Recargar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiHome, mdiArrowLeft, mdiRefresh } from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            mdiHome,
            mdiArrowLeft,
            mdiRefresh
        };
    },
    methods: {
        ...mapActions("dataFromServerStore", ["setExistsData"]),
        GoBack() {
            this.setExistsData(true);
            this.$router.go(-1);
        },
        Reload() {
            location.reload();
        },
        GoHome() {
            this.setExistsData(true);
            this.$router.push("/");
        }
    },
    computed: {
        ...mapGetters("dataFromServerStore", ["existsRecords"]),
    }
};
</script>
<style scoped lang="scss">
.custom-card-actions {
    justify-content: space-around;
}

@media (max-width: 600px) {
    .custom-card-actions {
        flex-direction: column;
        margin-left: 0px !important;
    }

    .custom-card-actions>.v-btn {
        margin-top: 10px;
        width: 90%;
    }
}

.bg-gradient {
    background: linear-gradient(to right, red, orange);
}
</style>
