const GetUSGSOverlay = (bounds, srcImage, mapOpacity) => {
    // eslint-disable-next-line
    class USGSOverlay extends google.maps.OverlayView {
        constructor(bounds, image, mapOpacity) {
            super();
            // Initialize all properties.
            this.bounds_ = bounds;
            this.image_ = image;
            this.opacity_ = mapOpacity;
            // Define a property to hold the image's div. We'll
            // actually create this div upon receipt of the onAdd()
            // method so we'll leave it null for now.
            this.div_ = null;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
            this.div_ = document.createElement("div");
            this.div_.style.borderStyle = "none";
            this.div_.style.borderWidth = "0px";
            this.div_.style.position = "absolute";
            this.div_.style.opacity = this.opacity_;

            // Create the img element and attach it to the div.
            const img = document.createElement("img");

            img.src = this.image_;
            img.style.width = "100%";
            img.style.height = "100%";
            img.style.position = "absolute";
            img.style.left = "0px";
            this.div_.appendChild(img);

            // Add the element to the "overlayLayer" pane.
            const panes = this.getPanes();

            panes.overlayLayer.appendChild(this.div_);
        }
        draw() {
            // We use the south-west and north-east
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            const overlayProjection = this.getProjection();
            // Retrieve the south-west and north-east coordinates of this overlay
            // in LatLngs and convert them to pixel coordinates.
            // We'll use these coordinates to resize the div.
            const sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
            const ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

            // Resize the image's div to fit the indicated dimensions.
            if (this.div_) {
                this.div_.style.left = sw.x + "px";
                this.div_.style.top = ne.y + "px";
                this.div_.style.width = ne.x - sw.x + "px";
                this.div_.style.height = sw.y - ne.y + "px";
            }
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
            if (this.div_) {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
            }
        }
        setOpacity(newValue) {
            if (this.div_) {
                this.div_.style.opacity = newValue;
            }
        }
    }

    return new USGSOverlay(bounds, srcImage, mapOpacity);
};

const GetDefaultGmapSettings = () => {
    return {
        mapTypeId: "hybrid",
        mapId: "DefaultGmapSettings",
        infoContent: "",
        center: { lat: -31.33969, lng: -62.195922 },
        style: "width:100%; height: 100%;",
        options: {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: true,
            disableDefaultUI: true,
        },
    };
};

export { GetUSGSOverlay, GetDefaultGmapSettings };
