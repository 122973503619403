import ReportsService from "../../../services/reports-tool.services";
import { router } from "../../../router/NewRouter";

const getDefaultState = () => {
    return {
        reportInfo: null,
        reportDescription: "",
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getReportInfo(state) {
            if (state.reportInfo == null) return null;

            // TODO: Dejamos el nombre getReport ya que la info que retorna no es un array, sino info del reporte.
            // Corregir en los backends la props "reports" ya que no es un array.
            return state.reportInfo.reports;
        },
        GetReportDescription(state) {
            return state.reportDescription;
        },
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        SetReportInfo(state, value) {
            if (value == null) return;

            state.reportDescription = value.reports.description;
            state.reportInfo = value;
        },
        UpdateReportDescription(state, newDescription) {
            state.reportDescription = newDescription;
        },
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async SetReportInfoAsync({ commit, dispatch }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });
            const fieldId = router.currentRoute.params.fieldId;
            const reportId = router.currentRoute.params.reportId;
            const data = await ReportsService.GetAllReportInfo(reportId, fieldId);
            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (!data) return;

            commit("SetReportInfo", data);
        },
        UpdateReportDescription({ commit }, description) {
            commit("UpdateReportDescription", description);
        }
    },
};
