<template>
    <div v-if="showActivityBar" class="activity-bar justify-space-around" :class="{ 'hidden': isHidden }">
        <router-link :to="{ name: 'Home' }">
            <i class="fas fa-home"></i>
        </router-link>

        <!--<button @click="goToNotifications">
            <i class="fas fa-bell"></i>
        </button> -->

        <router-link v-if="user != null"
            :to="userAdmin ? { name: 'Producers' } : { name: 'Fields', params: { producerId: user.ProducerId } }">
            <i class="fas" :class="{
                'fa-map-marked-alt': !userAdmin,
                'fa-users': userAdmin
            }"></i>
        </router-link>

        <button @click="CopyAndShowSharedPage">
            <i class="fas fa-share-alt"></i>
        </button>

        <button @click="SetMenuVisibility(!isMenuVisible)" v-if="user">
            <i class="fas fa-bars"></i>
        </button>

        <my-go-top-button />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OkMessageAsync } from "@/helpers/sweet-alerts.helper";
export default {
    data() {
        return {
            isHidden: false,
            lastScrollY: 0
        };
    },
    mounted() {
        // window.addEventListener("scroll", this.HandleScroll);
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.HandleScroll);
    },
    computed: {
        ...mapGetters("guiStore", ["isMenuVisible", "showActivityBar"]),
    },
    methods: {
        ...mapActions("guiStore", ["SetMenuVisibility"]),
        CopyAndShowSharedPage() {
            const url = window.location.href;
            navigator.clipboard.writeText(url);
            const html = `
                <a href="${url}" target="_blank">
                    <b>${url}</b>
                </a>
                <br/><br/>
                El link ya está listo para que lo pegues donde quieras!
            `;
            OkMessageAsync("Sección copiada!", "", html);
        },
        // HandleScroll() {
        //     const currentScrollY = window.scrollY;
        //     this.isHidden = currentScrollY > this.lastScrollY && currentScrollY > 100;
        //     this.lastScrollY = currentScrollY;
        // }

    }
};
</script>

<style scoped lang="scss">
.activity-bar {
    align-items: center;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: linear-gradient(to bottom, rgba($theme-color, 0.9), rgba($theme-color, 0.9));
    backdrop-filter: blur(20px);
    border-top: 1px solid #000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out;

    &.hidden {
        transform: translateY(80%);
    }

    @media (min-width: 800px) {
        display: none;
    }

    button,
    a {
        background: none;
        border: none;
        font-size: 24px;
        color: #ffffff;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }
}

.activity-bar button:focus {
    outline: none;
}
</style>
