import * as BaseServices from "./base.services";

const ReportsService = () => {};

ReportsService.GetReportsProcessListInfoByFieldIdAsync = async (fieldId) => {
    const response = await BaseServices.AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/tool-reports/field/${fieldId}`);
    if (!response.data) return null;
    if (response.data.reports.length > 0) response.data.reports.sort((a, b) => b.id - a.id);
    return response.data;
};

ReportsService.GetAllReportInfo = async (reportId, fieldId) => {
    const response = await BaseServices.AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/tool-reports/${reportId}/field/${fieldId}`);
    if (!response.data) return null;
    return response.data;
};

ReportsService.UploadReportByFieldIdAsync = async (fieldId, formData) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/tool-reports/field/${fieldId}`;
    const result = await BaseServices.AxiosPostAsync(url, formData);
    //console.log(result);
    return result;
};

ReportsService.UpdateReportByReportIdAsync = async (reportId, formData) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/tool-reports/field/${reportId}`;
    const result = await BaseServices.AxiosPatchAsync(url, formData);
    return result;
};

ReportsService.DeleteReportAsync = async (reportId) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/tool-reports/${reportId}`;
    const result = await BaseServices.AxiosDeleteAsync(url);
    return result;
};

ReportsService.UpdateDescriptionReportAsync = async (reportId, description) => {
    const url = `${process.env.VUE_APP_API_URL}/v1/tool-reports/${reportId}/update-description`;
    const result = await BaseServices.AxiosPatchAsync(url, { description });
    return result;
};

export default ReportsService;
