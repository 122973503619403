import { BaseResponse } from "./base.response";

class ProcessStateUpdatedResponse extends BaseResponse {
    constructor(id, action, responseCode, processId, processState) {
        super(id, action, responseCode);
        this.processId = processId;
        this.processState = processState;
    }
}

export { ProcessStateUpdatedResponse };
