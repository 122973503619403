import axios from "axios";
import { logout } from "./auth.services";
import { router } from "../router/NewRouter";
import * as LocalStorageCache from "./local-storage.cache.js";
import vuexStore from "../store/index.js";

class AxiosResult {
    constructor(data = null, error = null) {
        this.data = data;
        this.error = error;
    }
}

/**
 * Dispatches the existence of data from the server to the Vuex store.
 * @param {boolean} existsData
 */
const DispatchDataFromServer = (existsData) => {
    vuexStore.dispatch("dataFromServerStore/setExistsData", existsData);
};

const getAxiosRequestConfig = () => {
    const userData = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE));
    return {
        headers: {
            Authorization: `${userData?.token}`,
        },
    };
};

/**
 * @param {string} url
 * @param {string} errorMessage
 * @returns {AxiosResult | null}
 */
const _TryGetAxiosResultFromLocalStorageWhenAppIsOffline = (url, errorMessage) => {
    if (errorMessage != "Network Error") return null;

    const objectFromLS = LocalStorageCache.TryGetObjectFromLocalStorage(url);
    if (objectFromLS) {
        console.log("🚀Object recovered from local storage!", objectFromLS);
        return objectFromLS;
    }
};

/**
 * @param {string} url
 * @returns {AxiosResult}
 */
export const AxiosGetAsync = async (url) => {
    const result = new AxiosResult();

    try {
        const resultFromAxios = await axios.get(url, getAxiosRequestConfig());
        result.data = resultFromAxios.data;
        LocalStorageCache.TrySaveObjectToLocalStorage(url, result);
        DispatchDataFromServer(true);
        return result;
    } catch (error) {
        // Si no hay respuesta, asumimos que no hay conexión a internet
        if (!error.response) {
            // Tratamos de recuperar el objeto desde el local storage.
            const objectFromLS = _TryGetAxiosResultFromLocalStorageWhenAppIsOffline(url, error.message);
            if (objectFromLS) return objectFromLS;
            DispatchDataFromServer(false);
        }

        result.error = error;
    }

    if (result.error?.response?.status === 401) {
        logout(router.currentRoute.fullPath);
        return result;
    }

    console.log(`⚠️ ${result.error}`);

    return result;
};

export const AxiosGetWithoutJWTAsync = async (url, config = null) => {
    const result = {
        data: null,
        error: null,
    };

    await axios
        .get(url, config)
        .then((response) => (result.data = response.data))
        .catch((error) => {
            result.error = error;
            console.log(error.response);
        });

    return result;
};

//TODO: Mejorar el retorno ya que queda muy confuso de utilizar luego (por ej cuando se produce un error)
export const AxiosPostAsync = async (url, body) => {
    const result = {
        data: null,
        error: null,
    };

    await axios
        .post(url, body, getAxiosRequestConfig())
        .then((response) => (result.data = response.data))
        .catch((error) => {
            result.error = error;
            console.log(error.response);
        });

    if (result.error?.response?.status === 401) {
        logout(router.currentRoute.fullPath);
        return result;
    }

    return result;
};

export const AxiosPutAsync = async (url, body = null) => {
    const result = {
        data: null,
        error: null,
    };

    await axios
        .put(url, body, getAxiosRequestConfig())
        .then((response) => (result.data = response.data))
        .catch((error) => (result.error = error));

    if (result.error?.response?.status === 401) {
        logout(router.currentRoute.fullPath);
        return result;
    }

    return result;
};

export const AxiosPatchAsync = async (url, body = null) => {
    const result = {
        data: null,
        error: null,
    };

    await axios
        .patch(url, body, getAxiosRequestConfig())
        .then((response) => (result.data = response.data))
        .catch((error) => (result.error = error));

    result.error && console.log(result.error);

    if (result.error?.response?.status === 401) {
        logout(router.currentRoute.fullPath);
        return result;
    }

    return result;
};

export const AxiosDeleteAsync = async (url) => {
    const result = {
        data: null,
        error: null,
    };

    await axios
        .delete(url, getAxiosRequestConfig())
        .then((response) => (result.data = response.data))
        .catch((error) => {
            result.error = error;
            console.log(error.response);
        });

    if (result.error?.response?.status === 401) {
        logout(router.currentRoute.fullPath);
        return result;
    }

    return result;
};

export const API_URL = process.env.VUE_APP_API_URL;
