<template>
    <span class="my-container">
        <v-text-field v-model="search" :label="label" class="" type="text" @keyup="KeyUpEvent" @focus="ShowOptions"
            @blur="DelayedLostFocus" clearable />
        <div class="options-container" v-show="optionsContainerVisible">
            <div class="item" v-for="item in posibleItems.slice(0, 10)" :key="item" @click="ClickOption(item)">{{ item }}
            </div>
        </div>
    </span>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        value: {
            type: String,
            require: true
        },
        label: {
            type: String,
            default: ""
        },
        suggestedItems: {
            type: Number,
            default: 8
        }
    },
    data() {
        return {
            optionsContainerVisible: false,
            posibleItems: [],
            search: "",
            cancelLostFocus: false
        };
    },
    mounted() {
        this.posibleItems = this.items;
    },
    methods: {
        KeyUpEvent() {
            this.SearchItems();
        },
        ShowOptions() {
            this.optionsContainerVisible = true;
        },
        SearchItems() {
            if (this.search == "")
                this.posibleItems = this.items;
            else
                this.posibleItems = this.items.filter(x => x.toLowerCase().includes(this.search.toLowerCase()));

            console.log("111");
            this.optionsContainerVisible = this.posibleItems.length > 0;
        },
        DelayedLostFocus() {
            setTimeout(() => {
                this.LostFocus();
            }, 500);
        },
        LostFocus() {
            if (this.cancelLostFocus) {
                this.cancelLostFocus = false;
                return;
            }

            const options = this.items.filter(x => x.toLowerCase().includes(this.search?.toLowerCase()));
            if (options.length == 1) {
                this.search = options[0];
                this.$emit("new-item-selected", options[0]);
            } else {
                this.search = "";
                this.$emit("new-item-selected", null);
            }
            this.optionsContainerVisible = false;
        },
        ClickOption(item) {
            this.cancelLostFocus = true;
            this.search = item;
            this.$emit("new-item-selected", item);
            this.optionsContainerVisible = false;
        }
    },
    computed: {
        getSuggestedItems() {
            if (this.suggestedItems <= 0) return this.posibleItems;

            return this.posibleItems.slice(0, this.suggestedItems);
        }
    },
    watch: {
        value: function (newVal) {
            this.search = newVal;
        }
    }
};
</script>

<style scoped lang="scss">
.my-container {
    position: relative;
    display: block;
    padding: 0;
    width: 100%;
}

.options-container {
    position: absolute;
    text-align: left;
    top: 50px;
    left: 10px;
    width: calc(100% - 20px);
    background-color: #FFF;
    z-index: 9;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 15px 15px;
    font-size: 1rem;
    ;

    & .item {
        padding: 8px 15px;

        &:hover {
            cursor: pointer;
            background-color: #00000010;
        }
    }
}
</style>
