import { AxiosGetAsync } from "../../services/base.services";
import { IsTextInText } from "../../helpers/string.helper";

const getDefaultState = () => {
    return {
        dataFromServer: null,
        pluviometersListFromServer: [],
        pluviometersFilteredList: []
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getSafeData(state) {
            const result = JSON.parse(JSON.stringify(state.dataFromServer));
            return result;
        },
        getPluviometersList(state) {
            const result = JSON.parse(JSON.stringify(state.pluviometersFilteredList));
            return result.sort((a, b) => `${a._BusinessName}` < `${b._BusinessName}` ? -1 : 1);
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setDataFromServer(state, data) {
            if (data) state.dataFromServer = data;
        },
        setCoordenadas(state, { latitud, longitud }) {
            state.dataFromServer._Latitud = latitud;
            state.dataFromServer._Longitud = longitud;
        },
        setPluviometersListAsync(state, data) {
            state.pluviometersListFromServer = data;
            state.pluviometersFilteredList = data;
        },
        filterPluviometersAccordingFullName(state, textToSearch) {
            state.pluviometersFilteredList = state.pluviometersListFromServer.filter(p => IsTextInText(textToSearch, p._BusinessName) || IsTextInText(textToSearch, p._Productor) || IsTextInText(textToSearch, p._Establecimiento));
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setDataFromServer({ commit }, pluviometerId) {
            const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/pluviometer/${pluviometerId}`);

            if (result.error) return false;

            if (!result?.data) return false;

            commit("setDataFromServer", result.data);

            return true;

        },
        setCoordenadas({ commit }, { latitud, longitud }) {
            commit("setCoordenadas", { latitud, longitud });
        },
        async setPluviometersListAsync({ commit }) {
            const urlGet = `${process.env.VUE_APP_API_URL}/pluviometers-info`;

            const result = await AxiosGetAsync(urlGet);

            if (result.error) return false;

            if (!result?.data) return false;

            commit("setPluviometersListAsync", result.data);
        },
        filterPluviometersAccordingFullName({ commit }, searchText) {
            console.log(searchText);
            commit("filterPluviometersAccordingFullName", searchText);
        }
    },
};
