<template>
    <label class="custom-checkbox" :class="{ active: checked }" @click="toggleCheckbox">
        <div class="checkmark"></div>
        <span v-if="text != ''" class="ml-2">{{ text }}</span>
    </label>
</template>

<script>
export default {
    name: "CheckBox",
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        text: {
            type: String
        },
        onChange: {
            type: Function,
            default: () => { }
        },
    },
    methods: {
        toggleCheckbox() {
            this.onChange(!this.checked);
        },
    },
};
</script>

<style scoped lang="scss">
.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

.checkmark {
    width: 20px;
    height: 20px;
    border: 1px solid #00000060;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-checkbox.active .checkmark {
    background-color: #00000080;
}

.custom-checkbox.active .checkmark::after {
    content: '\2713';
    font-size: 18px;
    color: #fff;
}
</style>
