import Vue from "vue";
import "./plugins/fontawesome";
import App from "./App.vue";
import { router } from "./router/NewRouter";
import VueNotifications from "vue-notification";
import StringExtensions from "./Auxiliares/StringExtensions";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import store from "./store/index";
import { LoadGlobalComponents } from "./components/globals/global-components";
import "./web-socket/models/web-socket.model";

import vuetify from "./plugins/vuetify";

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import { LoadJsApiLoaderAsync, isGoogleMapsLoaded } from "./plugins/google.js.api.loader";

Vue.config.productionTip = false;

//#region ########################### MIXINS
import mixinConfirm from "./mixins/global/confirm";
import mixinGeneral from "./mixins/global/general";
Vue.mixin(mixinConfirm);
Vue.mixin(mixinGeneral);
import mixinValidators from "./mixins/helpers/validators";
Vue.mixin(mixinValidators);
//#endregion

Vue.use(VueNotifications);
Vue.use(StringExtensions);
Vue.use(VueSweetalert2);

LoadGlobalComponents(Vue);

let appVue;

async function InitializeAppAsync() {
    if (!isGoogleMapsLoaded) await LoadJsApiLoaderAsync();

    appVue = new Vue({
        vuetify,
        router,
        store,
        render: function (h) {
            return h(App);
        }
    }).$mount("#app");

    return appVue;
}

InitializeAppAsync();

export { appVue };
