<template>
  <div class="contenido">
    <div class="TituloSeccion">
      <font-awesome-icon :icon="icon" size="4x" :style="{ color: 'black' }" class="IconoTituloSeccion" />

      <div v-if="productor && establecimiento" class="aliLeft Ajuste1">
        <div>{{ seccion }} de {{ productor }}</div>
        {{ establecimiento }}
      </div>
      <div v-else class="">{{ seccion }}</div>
    </div>
    <slot></slot>

  </div>
</template>

<script>
export default {
  props: {
    seccion: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    productor: {
      type: String,
      required: false
    },
    establecimiento: {
      type: String,
      required: false
    }
  }
};
</script>

<style style lang="scss" scoped>
@import "../css/Componentes.scss";

.contenido {
  padding: 10px 10px;
  min-height: 50px;
  line-height: 25px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 800px) {
    min-height: 40px;
    line-height: 20px;
  }
}

.TituloSeccion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.IconoTituloSeccion {
  width: 30px !important;
  height: 30px !important;
  margin: auto 10px;
}

.Ajuste1 {
  line-height: 20px;
  font-weight: normal;
}
</style>
