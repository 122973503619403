import { AxiosGetAsync, AxiosPostAsync, AxiosPatchAsync, AxiosDeleteAsync, API_URL } from "./base.services";
// eslint-disable-next-line
import { AmbientationInfo, Ambientations, File } from "./models/ambientation-models.js";
// eslint-disable-next-line no-unused-vars
import { ProcessListInfo } from "./models/ambientation-models.js";

const AmbientationServices = () => { };

/**
 * @param {number} toolFieldId
 * @returns { ProcessListInfo | null }
 */
AmbientationServices.GetProcessAsync = async (toolFieldId) => {
    const url = `${API_URL}/v1/ambientation/processes-by-tool-field/${toolFieldId}`;
    const result = await AxiosGetAsync(url);
    if (result.data.records.length > 0) result.data.records.sort((a, b) => b.IdProcess - a.IdProcess);
    return result.data;
};

AmbientationServices.GetAvailableLayersAsync = async (processId) => {
    const url = `${API_URL}/v1/ambientation/process/${processId}/available-layers`;
    const result = await AxiosGetAsync(url);
    return result.data;
};

AmbientationServices.GetLayersByProcessAsync = async ({ toolFieldId, processId }) => {
    const url = `${API_URL}/v1/ambientation/process/${toolFieldId}/${processId}/layers`;
    const result = await AxiosGetAsync(url);
    return result.data;
};

AmbientationServices.GetFullProcessInfoAsync = async ({ toolFieldId, processId }) => {
    const url = `${API_URL}/v1/ambientation/process/${toolFieldId}/${processId}`;
    const result = await AxiosGetAsync(url);
    return result.data;
};

AmbientationServices.AddProcessAsync = async (formData, fieldId) => {
    const url = `${API_URL}/v1/ambientation/upload-files/${fieldId}`;
    const result = await AxiosPostAsync(url, formData);
    return result.data;
};

AmbientationServices.GetSuggestedAmbientationsAsync = async (toolFieldId, processId) => {
    const url = `${API_URL}/v1/ambientation/process/${toolFieldId}/${processId}/comparisions-results`;
    const result = await AxiosGetAsync(url);
    return result.data;
};

/**
 *
 * @param {{
 * toolFieldId: number,
 * processId: number,
 * visibility: boolean
 * }} param0
 * @returns
 */
AmbientationServices.UpdateProcessVisibilityAsync = async ({ toolFieldId, processId, visibility }) => {
    const result = await AxiosPatchAsync(`${API_URL}/v1/ambientation/process/${toolFieldId}/${processId}/update-visibility`, {
        value: visibility,
    });
    return result.error == null;
};

AmbientationServices.StartProcessAsync = async ({ processId, ambientations }) => {
    const result = await AxiosPostAsync(`${API_URL}/v1/ambientation/process/${processId}/layers`, {
        layersInfo: ambientations,
    });
    return result.data;
};

AmbientationServices.DeleteProcessByIdAsync = async (processId) => {
    const result = await AxiosDeleteAsync(`${API_URL}/v1/ambientation/process/${processId}`);
    return result.null == null;
};

AmbientationServices.ReprocessAmbientationByIdAsync = async (processId, ambientationId) => {
    const result = await AxiosPostAsync(`${API_URL}/v1/ambientation/process/${processId}/${ambientationId}`);
    return result.error == null;
};

/**
 * @param { Number } fieldId
 * @param { FormData } formData
 */
AmbientationServices.UploadFilesByToolFieldIdAsync = async (fieldId, formData) => {
    // return;
    const url = `${API_URL}/v1/ambientation/files/${fieldId}`;
    const result = await AxiosPostAsync(url, formData);
    //console.log(result);
    return result;
};

/**
 * @param { Number } processId
 * @param { FormData } formData
 */
AmbientationServices.UpdateFilesByProcessIdAsync = async (processId, formData) => {
    const url = `${API_URL}/v1/ambientation/files/${processId}`;
    const result = await AxiosPatchAsync(url, formData);
    return result;
};

/**
 * @param { Number } processId
 */
AmbientationServices.UpdateAmbientationFilePathAsync = async (ambientationId) => {
    const result = await AxiosPatchAsync(`${API_URL}/v1/ambientation/${ambientationId}/ambientation-file-path`);
    return result.error == null;
};

/**
 * @param {{
 * ambientationId: number,
 * value: boolean
 * }} param0
 */
AmbientationServices.UpdateVisibilityOfAnAmbientationAsync = async ({ ambientationId, value }) => {
    const result = await AxiosPatchAsync(`${API_URL}/v1/ambientation/${ambientationId}/update-visibility`, { value });
    return result.error == null;
};

/**
 * @param { Number } ambientationId
 */
AmbientationServices.DeleteAmbientationByIdAsync = async (ambientationId) => {
    const result = await AxiosDeleteAsync(`${API_URL}/v1/ambientation/${ambientationId}`);
    return result.error == null;
};

export default AmbientationServices;
