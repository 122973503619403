import ShowMapServices from "../../services/show-map.services";
export default {
    namespaced: true,
    state: {
        mapInfo: null
    },
    getters: {
        getMapInfo(state) {
            return state.mapInfo;
        },
    },
    mutations: {
        setShowMapDataAsync(state, result) {
            state.mapInfo = result;
        }
    },
    actions: {
        async SetShowMapDataAsync({ commit }, { endpoint }) {
            const result = await ShowMapServices.GetInfoByToolAsync(endpoint);
            commit("setShowMapDataAsync", result);
        }
    },
};
