export class NameStandardizer {
    static _standardizeName(name) {
        return name.trim().toLowerCase();
    }

    static _getFilteredItemsByParent(items, parentName, parentField) {
        return items.filter(item => item[parentField].toLowerCase() === parentName.toLowerCase());
    }

    static RemoveDuplicatesByName(items, nameField) {
        const uniqueNames = new Set();
        return items.filter(item => {
            const nameNormalized = NameStandardizer._standardizeName(item[nameField]);
            const isUnique = !uniqueNames.has(nameNormalized);
            uniqueNames.add(nameNormalized);
            return isUnique;
        });
    }

    static FilterDuplicateNames(items, parentName, parentField, nameField) {
        const itemsByParent = NameStandardizer._getFilteredItemsByParent(items, parentName, parentField);
        return NameStandardizer.RemoveDuplicatesByName(itemsByParent, nameField);
    }
}
