import HeatMapServices from "../../services/heat-map.services";
export default {
    namespaced: true,
    state: {
        heatMapDataPluviometer: null,
        heatMapDataPhreatimeter: null
    },
    getters: {
        heatMapDataPluviometer(state) {
            return state.heatMapDataPluviometer;
        },
        heatMapDataPhreatimeter(state) {
            return state.heatMapDataPhreatimeter;
        }
    },
    mutations: {
        setHeatMapDataPluviometer(state, result) {
            state.heatMapDataPluviometer = result;
        },
        setHeatMapDataPhreatimeter(state, result) {
            state.heatMapDataPhreatimeter = result;
        },
        setDefaultHeatMapDataPluviometer(state, result) {
            state.heatMapDataPluviometer = result;
        },
        setDefaultHeatMapDataPhreatimeter(state, result) {
            state.heatMapDataPhreatimeter = result;
        }
    },
    actions: {
        async setHeatMapDataPluviometer({ commit }, { dateFrom, dateTo }) {
            const result = await HeatMapServices.GetHetMapPluviometer(dateFrom, dateTo);
            commit("setHeatMapDataPluviometer", result);
        },
        async setHeatMapDataPhreatimeter({ commit }, { month }) {
            const result = await HeatMapServices.GetHetMapPhreatimeter(month);
            commit("setHeatMapDataPhreatimeter", result);
        },
        async setDefaultHeatMapDataPluviometer({ commit }) {
            const result = await HeatMapServices.GetDefaultHetMapPluviometer();
            commit("setDefaultHeatMapDataPluviometer", result);
        },
        async setDefaultHeatMapDataPhreatimeter({ commit }) {
            const result = await HeatMapServices.GetDefaultHetMapPhreatimeter();
            commit("setDefaultHeatMapDataPhreatimeter", result);
        },

    },
};
