import { AxiosPutAsync } from "./base.services";

/**
 * @returns Return empty if ok, else return error message
 */
const TryUpdatePassAsync = async ({ currentPass, newPass, userId }) => {
    const result = await AxiosPutAsync(`${process.env.VUE_APP_API_URL}/user/new-pass`, { currentPass, newPass, userId });
    if (result.error) return result.error;

    return "";
};

/**
 * Return empty if ok, else return error message
 */
const TryUpdateProductorAsync = async ({ id, name, lastName, phone, country, seller, userName, email, businessName }) => {
    const body = { id, name, lastName, phone, country, seller, userName, email, businessName };
    const result = await AxiosPutAsync(`${process.env.VUE_APP_API_URL}/user`, body);
    if (result.error) return result.error;

    return "";
};

export {
    TryUpdatePassAsync, TryUpdateProductorAsync
};
