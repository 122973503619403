import { TrySetUserIdInTheServersWebSocket } from "../../web-socket/models/web-socket.model";
import { UpdateWebsocketCircle } from "../../web-socket/utils/dom.utils";

export default {
    namespaced: true,
    state: {
        user: null,
        profileModalVisible: false
    },
    getters: {
        getUserRole(state) {
            return state.user?.RolUsuario;
        },
        userAdmin(state) {
            if (state.user?.RolUsuario == 99)
                return true;
            return false;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user == null) {
                localStorage.removeItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE);
                UpdateWebsocketCircle();
                return;
            }

            localStorage.setItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE, JSON.stringify(user));
            UpdateWebsocketCircle(1);
            TrySetUserIdInTheServersWebSocket();
        },
        setProfileModalVisible(state, visibility) {
            state.profileModalVisible = visibility;
        },
    },
    actions: {
        setUser({ commit }, user) {
            commit("setUser", user);
        },
        setUserFromLocalStorage({ commit }) {
            commit("setUser", JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_KEY_LOCAL_STORAGE)));
        },
        setProfileModalVisible({ commit }, visibility) {
            commit("setProfileModalVisible", visibility);
        }
    },
};
