<template>
    <div class="component-content">
        <apexchart class="grafic-styles" type="bar" :options="chartOptions" :series="chartSeries" height="95%" />
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        dataX: {
            type: Array,
            required: true,
        },
        dataY: {
            type: Array,
            required: true,
        },
        labelX: {
            type: String,
            default: "",
        },
        labelY: {
            type: String,
            default: "",
        },
        fillsColors: {
            type: Array,
            default: () => ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
        },
        toolTipTitle: {
            type: String,
            default: "",
        }
    },
    computed: {
        chartSeries() {
            return [{ data: this.dataY, name: this.toolTipTitle }];
        },
        chartOptions() {
            return {
                chart: {
                    height: "100%",
                    type: "bar",
                },
                colors: this.fillsColors,
                plotOptions: {
                    bar: {
                        columnWidth: "65%",
                        distributed: true,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: this.dataX,
                    labels: {
                        show: true,
                        style: {
                            fontSize: "10px",
                        },
                    },
                    title: {
                        text: this.labelX,
                        style: {
                            fontSize: "10px",
                            fontWeight: 500,
                        },
                    },
                },
                yaxis: {
                    title: {
                        text: this.labelY,
                        style: {
                            fontSize: "10px",
                            fontWeight: 300,
                        },
                    },
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;

    @media (max-width: 800px) {
        height: 300px;
    }
}

.grafic-styles {
    // width: calc(100% - 20px);
    max-width: 800px;
    height: 100%; // asegurarte que la altura sea 100%
    width: 100%;
}

@media (max-width: 800px) {
    .grafic-styles {
        // width: calc(100% - 10px);
        max-width: 360px;
        width: 100%;
    }
}
</style>
