import { AxiosGetAsync } from "../../services/base.services";
import { router } from "../../router/NewRouter";

const GetDefaultState = () => {
    return {
        producer: {
            id: "",
            name: "",
            businessName: "",
        },
        field: {
            id: "",
            name: "",
        },
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getProducer(state) {
            return {
                id: state.producer.id,
                name: state.producer.name,
                businessName: state.producer.businessName
            };
        },
        getField(state) {
            return {
                id: state.field.id,
                name: state.field.name,
            };
        },
    },
    mutations: {
        SetProducer(state, { name, id, businessName }) {
            if (businessName == null) return;

            state.producer.id = id;
            state.producer.name = name;
            state.producer.businessName = businessName;
        },
        SetField(state, { name, id }) {
            state.field.name = name;
            state.field.id = id;
        },
    },
    actions: {
        async TrySetProducerAndFieldInfoAsync({ commit }) {
            const producerId = router.currentRoute.params.producerId;
            if (!producerId) return false;

            const fieldId = router.currentRoute.params.fieldId;

            const axiosResult = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/producer-and-field/${producerId}/${fieldId}`);
            if (axiosResult.error) return false;

            /** @type { { producer: { ID, Nombre, Apellido, BusinessName }, field: { ID, Nombre } } }  */
            const producerAndFieldInfo = axiosResult.data;

            const producer = producerAndFieldInfo.producer;
            commit("SetProducer", {
                id: producer.ID,
                name: `${producer.Nombre} ${producer.Apellido}`,
                businessName: producer.BusinessName
            });

            const field = producerAndFieldInfo.field;
            if (field) {
                commit("SetField", {
                    id: field.ID,
                    name: field.Nombre
                });
            }

            return true;
        },
    },
};
