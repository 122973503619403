import PrescriptionServices from "../../../services/prescription.services";

const getDefaultState = () => {
    return {
        processesInfo: null
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getPrescriptionProcessesInfo(state) {
            return state.processesInfo;
        },
        getPrescriptionLastProccessToShowToProducer(state) {
            if (state.processesInfo == null) return null;

            if (state.processesInfo.records.length === 0) return null;
            return state.processesInfo.records[0].processId;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setPrescriptionProcessesInfo(state, value) {
            if (value == null) return;

            state.processesInfo = value;
            state.processesInfo.records.sort((a, b) => b.processId - a.processId);
        },
        updatePrescriptionProcessState(state, { processId, processState }) {
            const process = state.processesInfo?.records.find(x => x.processId === processId);
            if (process) process.stateId = processState;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setPrescriptionProcessesInfo({ commit }, toolFieldId) {
            const data = await PrescriptionServices.GetPrescriptionProcessListInfoByToolFieldIdAsync(toolFieldId);
            if (!data) return;

            commit("setPrescriptionProcessesInfo", data);
        },
        updatePrescriptionProcessState({ commit }, { processId, processState }) {
            commit("updatePrescriptionProcessState", { processId, processState });
        },
    }
};
