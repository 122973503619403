import AmbientationServices from "../../../services/ambientation-records.services";

export default {
    namespaced: true,
    state: {
        ambientationsInfo: null,
    },
    getters: {
        getAmbientations(state) {
            if (state.ambientationsInfo == null) return [];

            return state.ambientationsInfo.ambientations;
        },
        getAmbientationsInfo(state) {
            return state.ambientationsInfo;
        },
    },
    mutations: {
        setAmbientationsInfo(state, value) {
            if (value == null) return;

            state.ambientationsInfo = value;
            for (let x = 0; x < state.ambientationsInfo.ambientations.length; x++) {
                state.ambientationsInfo.ambientations[x].categoriesInfo.sort((a, b) => {
                    if (a.rangeFrom == null) return -1;
                    if (a.rangeFrom < b.rangeFrom) return -1;
                    return 1;
                });
                state.ambientationsInfo.ambientations[x].layers.forEach((layer) => {
                    layer.order = LayerOrderAux[layer.name] ?? 999;
                });
            }

            state.ambientationsInfo.ambientations.sort((a, b) => {
                // Comparar por longitud de las capas, si eso es relevante
                if (a.layers.length < b.layers.length) return 1;
                if (a.layers.length > b.layers.length) return -1;

                // Si tienen la misma cantidad de capas, comparar por el 'order' de la primera capa
                if ((a.layers[0]?.order ?? 999) < (b.layers[0]?.order ?? 999)) return -1;
                if ((a.layers[0]?.order ?? 999) > (b.layers[0]?.order ?? 999)) return 1;

                return 0; // Si son iguales, mantener el orden actual
            });
            PreloadImagesAsync(state.ambientationsInfo.ambientations);
        },
        setProcessedAmbientationFilePath(state, { ambientationId, filePath, filePathRunning }) {
            const ambientation = state.ambientationsInfo?.ambientations.find((amb) => amb.id == ambientationId);
            if (ambientation) {
                ambientation.processedAmbientationFilePath = filePath;
                ambientation.processedAmbientationFilePathRunning = filePathRunning;
            }
        },
        setVisibilityOfAnAmbientation(state, { ambientationId, visible }) {
            const ambientation = state.ambientationsInfo?.ambientations.find((amb) => amb.id == ambientationId);
            if (ambientation) ambientation.visibleForClients = visible;
        },
        ResetState(state) {
            state.ambientationsInfo = null;
        },
    },
    actions: {
        async setAmbientationsInfo({ commit, dispatch }, { toolFieldId, processId }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });
            const data = await AmbientationServices.GetFullProcessInfoAsync({ toolFieldId, processId });
            if (!data) return;

            commit("setAmbientationsInfo", data);

            dispatch("processingDialogStore/hideProcessing", null, { root: true });
        },
        setProcessedAmbientationFilePath({ commit }, { ambientationId, filePath, filePathRunning }) {
            commit("setProcessedAmbientationFilePath", { ambientationId, filePath, filePathRunning });
        },
        async setVisibilityOfAnAmbientationAsync({ commit, dispatch }, { ambientationId, visible }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });

            const data = await AmbientationServices.UpdateVisibilityOfAnAmbientationAsync({ ambientationId, value: visible });

            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (data) {
                commit("setVisibilityOfAnAmbientation", { ambientationId, visible });
                dispatch("reportsSection2/SetReportInfoAsync", null, { root: true });
            }
        },
        async HideAmbientationAsync({ dispatch }, ambientationId) {
            await dispatch("setVisibilityOfAnAmbientationAsync", { ambientationId, visible: false });
        },
        ResetState({ commit }) {
            commit("ResetState");
        },
    },
};

const PreloadImagesAsync = async (ambientations) => {
    for (const ambientation of ambientations ?? []) {
        if (ambientation.geoImage?.src) {
            const img = new Image();
            img.src = ambientation.geoImage.src;
        }
    }
};

const LayerOrderAux = {
    Elevación: 1,
    Arena: 2,
    Limo: 3,
    Arcilla: 4,
    "Electroconductividad a 90cm": 5,
    "Electroconductividad a 30cm": 6,
    Superficial: 7,
    "En profundidad (150cm)": 8,
    "Conductividad eléctrica": 9,
    "Materia Orgánica": 10,
    pH: 11,
    "Capacidad Intercambio Catiónico": 12,
    Calcio: 13,
    Magnesio: 14,
    Sodio: 15,
    Potasio: 16,
    Fósforo: 17,
    Azufre: 18,
    Zinc: 19,
    Boro: 20,
    Manganeso: 21,
    Cobre: 22,
    Hierro: 23,
    Cobalto: 24,
    Molibdeno: 25,
};
