import { AxiosGetAsync } from "./base.services";

const GetGeneralStatusForProducerAsync = async () => {
    const currentYear = new Date().getFullYear();
    const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/reports/general-status-for-producers/${currentYear}`);
    if (result.error) return result.error;

    return result.data;
};

export {
    GetGeneralStatusForProducerAsync
};

