const ResponseBaseCode = {
    Ok: 1,
    Fail: 2,
};

class BaseResponse {
    constructor(id, action, responseCode) {
        this.id = id;
        this.action = action;
        this.responseCode = responseCode;
    }

    Ok() {
        return this.responseCode == ResponseBaseCode.Ok;
    }
}

module.exports = {
    ResponseBaseCode,
    BaseResponse,
};
