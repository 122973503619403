<template>
    <div class="w100" v-if="profileModalVisible">
        <div class="modal-backdrop" v-on:click="setProfileModalVisible(false)">
        </div>
        <div class="modal" role="dialog">
            <header class="modal-header" ref="titleModal">
                Edición perfil
            </header>
            <section class="mT10" id="modalDescription">

                <div class="face oHidden">
                    <div class="content aliCenter">
                        <!-- <div class="TituloLogin B">Login</div> -->
                        <div class="field-wrapper">
                            <input class="aliLeft" placeholder="aux" v-model="currentPass"
                                :type="passVisible1 ? 'text' : 'password'"
                                :class="{ 'is-invalid': v$.currentPass.$error }" />
                            <label>Contraseña actual</label>
                            <span class="invalid-feedback" v-for="(error, index) in v$.currentPass.$errors"
                                :key="index">
                                {{ error.$message }}
                            </span>
                            <span v-if="!passVisible1" class="append-inner-icon" @click="passVisible1 = !passVisible1">
                                <svg-icon type="mdi" :path="mdiEyeOutline" class="w-100 h-100"></svg-icon>
                            </span>
                            <span v-if="passVisible1" class="append-inner-icon" @click="passVisible1 = !passVisible1">
                                <svg-icon type="mdi" :path="mdiEyeOffOutline" class="w-100 h-100"></svg-icon>
                            </span>
                        </div>
                        <div class="field-wrapper">
                            <input class="aliLeft" placeholder="aux" v-model="newPass"
                                :type="passVisible2 ? 'text' : 'password'"
                                :class="{ 'is-invalid': v$.newPass.$error }" />
                            <label>Nueva contraseña</label>
                            <span class="invalid-feedback" v-for="(error, index) in v$.newPass.$errors" :key="index">
                                {{ error.$message }}
                            </span>
                            <span v-if="!passVisible2" class="append-inner-icon" @click="passVisible2 = !passVisible2">
                                <svg-icon type="mdi" :path="mdiEyeOutline" class="w-100 h-100"></svg-icon>
                            </span>
                            <span v-if="passVisible2" class="append-inner-icon" @click="passVisible2 = !passVisible2">
                                <svg-icon type="mdi" :path="mdiEyeOffOutline" class="w-100 h-100"></svg-icon>
                            </span>
                        </div>
                        <div class="field-wrapper">
                            <input class="aliLeft" placeholder="aux" v-model="confirmPass"
                                :type="passVisible3 ? 'text' : 'password'"
                                :class="{ 'is-invalid': v$.confirmPass.$error }" />
                            <label>Confirmar contraseña</label>
                            <span class="invalid-feedback" v-for="(error, index) in v$.confirmPass.$errors"
                                :key="index">
                                {{ error.$message }}
                            </span>
                            <span v-if="!passVisible3" class="append-inner-icon" @click="passVisible3 = !passVisible3">
                                <svg-icon type="mdi" :path="mdiEyeOutline" class="w-100 h-100"></svg-icon>
                            </span>
                            <span v-if="passVisible3" class="append-inner-icon" @click="passVisible3 = !passVisible3">
                                <svg-icon type="mdi" :path="mdiEyeOffOutline" class="w-100 h-100"></svg-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-footer">
                <button type="button" class="Boton btnSecondary"
                    @click="setProfileModalVisible(false)">Cancelar</button>
                <button type="button" class="Boton btnPrimary" @click="Submit()">Aceptar</button>
            </footer>
        </div>

    </div>
</template>
<script>
import { TryUpdatePassAsync } from "../../services/user.services";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs, helpers } from "@vuelidate/validators";
import { mapState, mapActions } from "vuex";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { NotifyOk, NotifyError } from "../../helpers/vue-notifications.helper";
export default {
    name: "EditProfileModal",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            currentPass: "",
            newPass: "",
            confirmPass: "",
            passVisible1: false,
            passVisible2: false,
            passVisible3: false,
            mdiEyeOutline, mdiEyeOffOutline
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions("userStore", ["setProfileModalVisible"]),
        async Submit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                const result = await TryUpdatePassAsync({
                    currentPass: this.currentPass,
                    newPass: this.newPass,
                    userId: this.user.ID
                });
                if (result == "") {
                    NotifyOk("Contraseña actualizada!");
                    this.LimpiarDatos();
                    this.v$.$reset();
                    this.setProfileModalVisible(false);
                    return;
                }
                NotifyError(result);
            }
        },
        LimpiarDatos() {
            this.currentPass = "";
            this.newPass = "";
            this.confirmPass = "";
        }

    },
    computed: {
        ...mapState("userStore", ["profileModalVisible"]),
    },
    validations() {
        return {
            currentPass: {
                required: helpers.withMessage("Este campo es requerido!", required),
                minLength: helpers.withMessage("La contraseña debe contener al menos 6 caracteres!", minLength(6))
            },
            newPass: {
                required: helpers.withMessage("Este campo es requerido!", required),
                minLength: helpers.withMessage("La contraseña debe contener al menos 6 caracteres!", minLength(6))
            },
            confirmPass: {
                required: helpers.withMessage("Este campo es requerido!", required),
                sameAs: helpers.withMessage("Las contraseñas ingresadas no coinciden!", sameAs(this.$data.newPass))
            }
        };
    }
};
</script>
<style lang="scss" scoped>
.append-inner-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 35px;
    height: 35px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
</style>
