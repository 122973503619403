import { AxiosGetAsync } from "../../services/base.services";
import { IsTextInText } from "../../helpers/string.helper";

const getDefaultState = () => {
    return {
        dataFromServer: null,
        freatimetersListFromServer: [],
        freatimetersFilteredList: []
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getSafeData(state) {
            // Retorno una copia del objeto para que no se pueda modificar el original
            return JSON.parse(JSON.stringify(state.dataFromServer));
        },
        getPhreatimetersList(state) {
            const result = JSON.parse(JSON.stringify(state.freatimetersFilteredList));
            return result.sort((a, b) => `${a._BusinessName}` < `${b._BusinessName}` ? -1 : 1);
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setDataFromServer(state, data) {
            if (data) state.dataFromServer = data;
        },
        setCoordenadas(state, { latitud, longitud }) {
            state.dataFromServer._Latitud = latitud;
            state.dataFromServer._Longitud = longitud;
        },
        setPhreatimetersListAsync(state, data) {
            state.freatimetersListFromServer = data;
            state.freatimetersFilteredList = data;
        },
        filterPhreatimetersAccordingFullName(state, textToSearch) {
            state.freatimetersFilteredList = state.freatimetersFilteredList.filter(p => IsTextInText(textToSearch, p._BusinessName) || IsTextInText(textToSearch, p._Productor) || IsTextInText(textToSearch, p._Establecimiento));
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setDataFromServer({ commit }, freatimeterId) {
            const result = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/freatimeter/${freatimeterId}`);

            if (result.error) return false;

            if (!result?.data) return false;

            commit("setDataFromServer", result.data);
            return true;
        },
        setCoordenadas({ commit }, { latitud, longitud }) {
            commit("setCoordenadas", { latitud, longitud });
        },
        async setPhreatimetersListAsync({ commit }) {
            const urlGet = `${process.env.VUE_APP_API_URL}/freatimeters-info`;

            const result = await AxiosGetAsync(urlGet);

            if (result.error) return false;

            if (!result?.data) return false;

            commit("setPhreatimetersListAsync", result.data);
        },
        filterPhreatimetersAccordingFullName({ commit }, searchText) {
            console.log(searchText);
            commit("filterPhreatimetersAccordingFullName", searchText);
        }
    },
};

