import { BaseResponse } from "./base.response";

class AmbientationFilePathUpdatedResponse extends BaseResponse {
    constructor(id, action, responseCode, ambientationId, filePath, filePathRunning) {
        super(id, action, responseCode);
        this.ambientationId = ambientationId;
        this.filePath = filePath;
        this.filePathRunning = filePathRunning;
    }
}

export { AmbientationFilePathUpdatedResponse };
