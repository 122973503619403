import { appVue as app } from "../main";

const Notify = (text, title, type, seconds = 10) => {
    title = `<u>${title}</u>`;
    app.$notify({
        group: "Notificaciones",
        title: title, //Puede ser HTML
        type: type, //alert celeste, success verde
        speed: 500,
        duration: seconds * 1000,
        text: text, //Puede ser HTML
    });
};

export const NotifyOk = (text, title = "", seconds = 10) => {
    Notify(text, title, "success", seconds);
};

export const NotifyError = (text, title = "", seconds = 120) => {
    if (title == "") title = "Error";
    title = `⚠️ ${title}`;
    Notify(text, title, "error", seconds);
};

export const NotifyWarning = (text, title = "", seconds = 30) => {
    if (title == "") title = "Atención";
    title = `⚠️ ${title}`;
    Notify(text, title, "warn", seconds);
};

export const NotifyInfo = (text, title = "", seconds = 30) => {
    Notify(text, title, "info", seconds);
};

