import Swal from "sweetalert2";

const ConfirmAsync = async (title, text, icon) => {
    const result = await Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: "SI",
        cancelButtonText: "NO",
        reverseButtons: true
    })
        .then(result => {
            return result.isConfirmed;
        });
    return result;
};

const OkMessageAsync = async (title, text = "", html = "") => {
    const swalOptions = {
        title: title,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Ok!",
        customClass: {
            confirmButton: "bg-primary"
        }
    };
    if (text) swalOptions.text = text;
    if (html) swalOptions.html = html;
    return await Swal.fire(swalOptions);
};

//TODO: Cambiar nombre a WarmConfirmAsync
/**
 * @param {string} message 
 */
const WarmConfirm = async (message) => {
    return await ConfirmAsync("ATENCIÓN", message, "warning");
};

export { WarmConfirm, OkMessageAsync };
