import { render, staticRenderFns } from "./BreadcrumbItem.vue?vue&type=template&id=46ff8552&scoped=true"
import script from "./BreadcrumbItem.vue?vue&type=script&lang=js"
export * from "./BreadcrumbItem.vue?vue&type=script&lang=js"
import style0 from "./BreadcrumbItem.vue?vue&type=style&index=0&id=46ff8552&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ff8552",
  null
  
)

export default component.exports