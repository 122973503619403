import { BaseResponse } from "./base.response";

class ComparisionsResultsHasBeenCompletedResponse extends BaseResponse {
    constructor(id, action, responseCode, processId) {
        super(id, action, responseCode);
        this.processId = processId;
    }
}

export { ComparisionsResultsHasBeenCompletedResponse };
