/**
 * @param { String } input 
 */
const TryGetFileExtension = (input) => {
    if (input == null) return "";

    const parts = input.split(".");
    if (parts.length > 1) return parts.pop().toLowerCase();

    return "";
};

/**
 * @param { string } textToSearch 
 * @param { string} textToSearchIn 
 */
const IsTextInText = (textToSearch, textToSearchIn) => {
    return textToSearchIn.toLowerCase().includes(textToSearch.toLowerCase());
};

/**
 * @param { string } text 
 */
const GetFirstLetterOfEachWord = (text, initialSeparator = ".") => {
    if (text == null) return "";
    return text.split(" ").map((word) => word[0]).join(initialSeparator).toUpperCase();
};

/** @param { string } text */
const IsNullOrEmpty = (text) => {
    if ((typeof text) == "number") return false;
    if (text?.trim() == "") return true;
    return false;
};

export {
    TryGetFileExtension, IsTextInText,
    GetFirstLetterOfEachWord, IsNullOrEmpty
};
