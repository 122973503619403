import { router } from "../../router/NewRouter";

export default {
    namespaced: true,
    state: {
        existsData: true
    },
    getters: {
        existsRecords: state => {
            return state.existsData;
        }
    },
    mutations: {
        setExistsData(state, value) {
            state.existsData = value;
        }
    },
    actions: {
        setExistsData({ commit }, value) {
            const excludedRoutesNames = ["Login", "Home"];
            if (excludedRoutesNames.includes(router.currentRoute.name)) return;

            commit("setExistsData", value);
        }
    },
};
