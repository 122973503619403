export default {
    namespaced: true,
    state:{
        username: "",
        password: "",
        email: "",
        loginFocused: true
    },
    getters: {
    },
    mutations:{
        setLoginFocused(state, data) {
            state.loginFocused = data;
        },
        setUsername(state, data) {
            state.username = data;
        },
        setPassword(state, data) {
            state.password = data;
        },
        setEmail(state, data) {
            state.email = data;
        },
    },
    actions:{
        setLoginFocused({commit}, data) {
            commit("setLoginFocused", data);
        },
        setUsername({commit}, data) {
            commit("setUsername", data);
        },
        setPassword({commit}, data) {
            commit("setPassword", data);
        },
        setEmail({commit}, data) {
            commit("setEmail", data);
        },
    },
};
