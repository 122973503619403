import ReportsServices from "../../../services/reports-tool.services";
const getDefaultState = () => {
    return {
        processesInfo: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getReportsProcessesInfo(state) {
            return state.processesInfo;
        },
        getReportOfLastProcessToShowToProducer(state) {
            if (state.processesInfo == null) return null;

            if (state.processesInfo.reports.length === 0) return null;

            return state.processesInfo.reports[0].id;
        },
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        setReportsProcessesInfo(state, value) {
            if (value == null) return;

            state.processesInfo = value;
            state.processesInfo.reports.sort((a, b) => b.id - a.id);
        },
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async setReportsProcessesInfo({ commit }, fieldId) {
            const data = await ReportsServices.GetReportsProcessListInfoByFieldIdAsync(fieldId);

            if (!data) return;

            commit("setReportsProcessesInfo", data);
        },
        async deleteReport({}, reportId) {
            const result = await ReportsServices.DeleteReportAsync(reportId);

            if (result.error?.response?.data) return result.error.response.data.message;

            return result.error;
        },
    },
};
