import Clases from "../../Auxiliares/Clases";
import { mapState, mapGetters, mapActions } from "vuex";
import SlidingPagination from "vue-sliding-pagination";
export default {
    created() {
    },
    components: { SlidingPagination },
    methods: {
        ...mapActions("processingDialogStore", ["showProcessing", "hideProcessing"]),
        GetUserLogged() {
            return this.user;
        },
        KeyUser() {
            return Clases.KeyUser;
        },
        GetRandomGrayColor() {
            const range = [100, 150];
            const r = () => Math.floor(range[0] + Math.random() * (range[1] - range[0]));
            return `rgb(${r()}, ${r()}, ${r()})`;
        },
        async ShowProcessingDialog() {
            await this.showProcessing();
        },
        async HideProcessingDialog() {
            await this.hideProcessing();
        }
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapGetters("userStore", ["userAdmin"]),
        ...mapState(["online"]),
        ...mapGetters("processingDialogStore", ["processingDialogVisibility"]),
        ShowGUI() {
            if (this.$route.name == "Login")
                return false;

            if (this.$route.name == "ShowMap") {
                if (this.user == null)
                    return false;
            }

            return true;
        },
        AdminLogueado() {
            if (this.user == null) return false;

            return this.user.RolUsuario == 99;
        }
    }
};
