<template>
    <div class="category-container">
        <div class="references-title">{{ title }}</div>
        <div v-for="(color, index) in getColors" :key="index">
            <div class="category-item">
                <div class="color-box" :style="{ 'background-color': color }"></div>
                <div class="category-info">{{ getRanges[index] }}</div>

                <div class="category-info bottom-limit" v-if="index == getColors.length - 1 && showBottomLimit">
                    <div>{{ (getRanges[index + 1]) }}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        ranges: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            required: true
        },
        title: {
            type: String,
        },
        showTopLimit: {
            type: Boolean,
            default: true
        },
        showBottomLimit: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        getColors() {
            const result = [...this.colors];
            return result.reverse();
        },
        getRanges() {
            const result = [...this.ranges];
            return result.reverse();
        }
    },
    methods: {
        GetFixedValue(value) {
            if (value < 0) return `(${value})`;

            return value;
        }
    }
};
</script>

<style scoped lang="scss">
.category-container {
    position: relative;
    background-color: #FFFFFF80;
    padding: 10px;
    padding-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    min-width: 60px;
}

.references-title {
    font-size: 1rem;
    margin-bottom: 10px;
}

.category-item {
    display: flex;
    align-items: center;
    position: relative;
}

.color-box {
    width: 10px;
    height: 30px;
}

.category-info {
    font-size: 0.8rem;
    top: -8px;
    position: absolute;
    left: 15px;

    &.bottom-limit {
        top: auto;
        bottom: -8px;
    }
}
</style>
