<template>
    <div class="ContenedorGUI">
        <div class="ContenedorHeader" :class="headerClass">
            <v-row no-gutters class="h100">
                <v-col cols="6" class="h100 d-flex align-center">
                    <my-back-button />
                    <router-link :to="{ name: 'Home' }" class="d-flex align-center">
                        <img src="/img/logo-maiz-plus-blanco.png" alt="LogoMaizPlus" class="LogoHeader" />
                    </router-link>
                </v-col>

                <v-col cols="6" class="h100 d-flex justify-end align-center">
                    <!-- Share page -->
                    <div v-if="PWAInstalled" class="button-reload mr-4 d-flex" @click="CopyAndShowSharedPage()">
                        <svg-icon type="mdi" :path="mdiShareVariantOutline" class="w100 h100" />
                    </div>

                    <!-- Refresh page -->
                    <div v-if="PWAInstalled" @click="RefreshPage()" class="button-reload mr-4 d-flex">
                        <svg-icon type="mdi" :path="mdiWebRefresh" class="w100 h100" />
                    </div>

                    <!-- Menu icon -->
                    <font-awesome-icon v-if="user != null" @click="SetMenuVisibility(!isMenuVisible)"
                        :icon="!isMenuVisible ? 'bars' : 'times'" size="4x" :style="{ color: 'white' }"
                        class="IconoHeader d-flex mr-5" />

                    <router-link v-if="user == null" class="mr-2 d-flex" :to="{ name: 'Login' }">
                        <svg-icon type="mdi" :path="mdiLogin" class="svg-icon-menu"></svg-icon>
                    </router-link>
                </v-col>
            </v-row>
        </div>
        <div class="modal-backdrop" v-if="isMenuVisible" v-on:click="SetMenuVisibility(false)"></div>
        <div class="Menu" :class="{ 'ShowMenuOptions': isMenuVisible }">
            <div class="w100 aliLeft pR h100">
                <font-awesome-icon @click="SetMenuVisibility(false)" icon="times" size="4x" :style="{ color: 'white' }"
                    class="close-icon-menu" />
                <div class="only-mobile LogoMenuMobile">
                    <img class="LogoHeaderMenuMobile only-mobile" src="/img/logo-maiz-plus-blanco.png"
                        alt="LogoMaizPlus">
                </div>

                <GUILink tiene-borde-inferior>
                    Hola <span ref="NombreUsuario">{{ NombreUsuario() }}</span>!
                </GUILink>

                <GUILink icon="user-tie" tiene-borde-inferior @click.native="ClickEditProfile()">
                    Mi cuenta
                </GUILink>

                <GUILink route="/producers" icon="users" seccion="Producers" @click.native="SetMenuVisibility(false)"
                    v-if="userAdmin" tiene-borde-inferior>Productores</GUILink>

                <GUILink v-if="!userAdmin && user != null" :route="`/producers/${user.ProducerId}/fields`" icon="users"
                    seccion="Producers" @click.native="SetMenuVisibility(false)" tiene-borde-inferior>Mis Lotes
                </GUILink>

                <GUILink route="/Extended-Forecast" icon="cloud-sun-rain" @click.native="SetMenuVisibility(false)"
                    v-if="userAdmin" tiene-borde-inferior>
                    Pronósticos extendidos
                </GUILink>

                <div v-if="userAdmin" @click="GenerateReports()">
                    <GUILink icon="file-excel" tiene-borde-superior>Vencimientos de Herramientas</GUILink>
                </div>
                <div @click="GoToLogin()">
                    <GUILink icon="sign-out-alt" tiene-borde-superior>Salir</GUILink>
                </div>
            </div>
        </div>
        <EditProfileModal />
    </div>
</template>

<script>
import Clases from "../Auxiliares/Clases.js";
import GUILink from "./GUILink.vue";
import { Herramientas } from "../Auxiliares/Enums";
import EditProfileModal from "./modals/EditProfileModal.vue";
import { mapActions, mapGetters } from "vuex";
import { mdiLogin, mdiWebRefresh, mdiShareVariantOutline } from "@mdi/js";
import PWAInstalled from "../Auxiliares/PWAInstalled";
import { GenerateExcelAsync } from "@/Auxiliares/GenerateFile";
import { GetGeneralStatusForProducerAsync } from "../services/reports.service";
import { OkMessageAsync } from "../helpers/sweet-alerts.helper";

export default {
    name: "GUI",
    components: {
        GUILink, EditProfileModal
    },
    data() {
        return {
            Herramientas,
            startY: 0,
            scrollDirection: null,
            mdiLogin, mdiWebRefresh, mdiShareVariantOutline,
            PWAInstalled: PWAInstalled()
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
    },
    methods: {
        ...mapActions("userStore", ["setProfileModalVisible"]),
        ...mapActions("guiStore", ["SetMenuVisibility"]),
        handleScroll() {
            if (window.scrollY > this.startY) {
                this.scrollDirection = Clases.ScrollDirection.Down;
            } else {
                this.scrollDirection = Clases.ScrollDirection.Up;
            }

            this.startY = window.scrollY;
            if (this.startY <= 0)
                this.scrollDirection = null;
        },
        GoToLogin() {
            this.SetMenuVisibility(false);
            this.$router.push({ name: "Login" });
        },
        NombreUsuario() {
            if (this.GetUserLogged() != null) {
                return this.GetUserLogged().UserName;
            }
            return "...";
        },
        GoBack() {
            //console.log(this.$router);
            this.$router.back();
            //this.$router.go(-1);
        },
        ClickEditProfile() {
            console.log("ClickEditProfile");
            this.SetMenuVisibility(false);
            this.setProfileModalVisible(true);
        },
        RefreshPage() {
            window.location.reload();
        },
        async GenerateReports() {
            const headers = ["Productor", "Lote", "Id Herramienta", "Herramienta", "Observación"];
            const result = await GetGeneralStatusForProducerAsync();
            const data = result.map(item => [item.Productor, item.Lote, item.IdHerramienta, item.Herramienta, item.Observacion]);
            await GenerateExcelAsync(headers, data);
        },
        CopyAndShowSharedPage() {
            const url = window.location.href;
            navigator.clipboard.writeText(url);
            const html = `
                <a href="${url}" target="_blank">
                    <b>${url}</b>
                </a>
                <br/><br/>
                El link ya está listo para que lo pegues donde quieras!
            `;
            OkMessageAsync("Sección copiada!", "", html);
        }
    },
    computed: {
        ...mapGetters("guiStore", ["isMenuVisible"]),
        headerClass() {
            if (this.scrollDirection == Clases.ScrollDirection.Down && this.startY > 100) return "header-hide";

            return "";
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.close-icon-menu {
    position: absolute;
    right: 10px;
    top: 12px;
    height: 34px;
    width: 34px;
    border-radius: 100%;
    box-shadow: 0 0 0px rgba(255, 255, 255, 0.5);
    cursor: pointer;

    @media (max-width: 799px) {
        display: none;
    }
}

/*#region Scrollbar*/
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    // -webkit-box-shadow: inset 0 0 1px black;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    // -webkit-box-shadow: inset 0 0 16px black;
}

/*#endregion */

#herramientas {
    max-height: 360px;
    overflow-y: scroll;
}

.AjusteTitulo1 {
    margin-top: 15px;
}

body {
    margin: 0px;
}

.IconoHeader {
    height: 34px;
    width: 34px;
    border-radius: 100%;
    // border:1px solid white;
    box-shadow: 0 0 0px rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.IconoLogout {
    height: 34px;
    width: 34px;
}

.svg-inline--fa {
    vertical-align: auto;
}

// .contenedorGUI{
//     box-shadow: 0 0px 10px #000;
// }
.ContenedorHeader {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 70px;
    line-height: 70px;
    width: 100vw;
    background-color: $primary;
    box-shadow: 0 0px 10px #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, $LightGreen , $DarkGreen);
    transition: all .2s ease-in-out;

    @media (max-width: 799px) {
        display: none;
    }

}

.header-hide {
    top: -80px;
}

.LogoHeader {
    width: 130px;

    @media (min-width: 799px) {
        width: 140px;
    }
}

.Menu {
    box-sizing: content-box;
    z-index: 11;
    position: fixed;
    right: -100%;
    top: 0px;
    height: 100vh;
    width: 80%;
    max-width: 450px;
    background-color: $DarkGreen;
    color: white;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    box-shadow: 0 0px 10px #000000;

    @media (max-width: 799px) {
        height: calc(100vh - 60px);
    }
}

.ShowMenuOptions {
    right: 0% !important;

    @media (max-width: 600px) {
        width: 100%;
    }
}

#BotonMenu {
    z-index: 12;
}

@media (min-width: 800px) {

    // desktop
    // .LogoHeader {
    //     height: 54px;
    // }

    .ContenedorHeader {
        height: $header-height;
        justify-content: space-between;
    }

    .ContenedorNav {
        position: relative;
        font-size: 0.9em;
    }
}

@media (max-width: 799px) {

    // mobile
    .hideFooter {
        bottom: -50px;
    }

    .LogoMenuMobile {
        height: 70px;
        display: grid;
        align-items: center;
        background-image: linear-gradient(to right, $LightGreen , $DarkGreen);
    }

    .LogoHeaderMenuMobile {
        width: 165px;
        margin-left: 10px;
        margin-top: 5px;
    }
}

.content-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.svg-icon-menu {
    width: 40px;
    height: 40px;
    color: white;
}

.button-reload {
    height: 34px;
    width: 34px;
    cursor: pointer;
    font-weight: 600;
    color: #FFFFFF;
}
</style>
