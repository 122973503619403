const GetDefaultState = () => {
    return {
        isMenuVisible: false,
        currentRouteName: ""
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        isMenuVisible(state) {
            return state.isMenuVisible;
        },
        showActivityBar(state) {
            const excludedRoutes = ["Login"];
            return !excludedRoutes.includes(state.currentRouteName);
        }
    },
    mutations: {
        SetMenuVisibility(state, isVisible) {
            state.isMenuVisible = isVisible;
        },
        SetCurrentRouteName(state, routeName) {
            state.currentRouteName = routeName;
        }
    },
    actions: {
        SetMenuVisibility({ commit }, isVisible) {
            commit("SetMenuVisibility", isVisible);
        },
        SetCurrentRouteName({ commit }, routeName) {
            commit("SetCurrentRouteName", routeName);
        }
    },
};
