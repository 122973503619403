<template>
    <router-link :to="breadcrumbItem.path" class="breadcrumb-link mr-2">
        {{ breadcrumbItem.label }} >
    </router-link>
</template>

<script>
export default {
    props: {
        breadcrumbItem: {
            type: Object,
            required: true,
        },
        isLastItem: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.breadcrumb-link {
    font-size: 1rem;
    text-decoration: none;
    color: #00000080;

    &:hover {
        text-decoration: underline;
        color: #000000BB;
    }

    @media (max-width: 799px) {
        width: 100%;
        display: block;
    }
}
</style>
