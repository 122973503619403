// src/utils/LoadJsApiLoader.js
import { Loader } from "@googlemaps/js-api-loader";

let isGoogleMapsLoaded = false;

const LoadJsApiLoaderAsync = async () => {
    if (!isGoogleMapsLoaded) {
        const loader = new Loader({
            apiKey: process.env.VUE_APP_API_KEY_GOOGLE_MAPS,
            version: "weekly",
            libraries: ["marker"]
        });
        await loader.load();
        console.log("✅ Google Maps loaded");
        isGoogleMapsLoaded = true;
    }
};

export { LoadJsApiLoaderAsync, isGoogleMapsLoaded };
