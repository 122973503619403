class PwaSettings { };

PwaSettings.Initialize = () => {
    console.log(process.env.NODE_ENV);

    if (process.env.NODE_ENV != "production") return false;

    InitSW();
};
function invokeServiceWorkerUpdateFlow(registration) {
    // TODO implement your own UI notification element
    const NewAvailableVersionDiv = document.getElementById("NewAvailableVersionDiv");
    console.log(NewAvailableVersionDiv);
    NewAvailableVersionDiv.style.display = "block";
    // notification.show("New version of the app is available. Refresh now?");
    NewAvailableVersionDiv.addEventListener("click", () => {
        if (registration.waiting) {
            console.log("registration.waiting");
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage("SKIP_WAITING");
        }
    });
}
const InitSW = async () => {

    // check if the browser supports serviceWorker at all
    if ("serviceWorker" in navigator) {
        // wait for the page to load
        window.addEventListener("load", async () => {
            // register the service worker from the file specified
            const registration = await navigator.serviceWorker.register("/service-worker.js");
            // ensure the case when the updatefound event was missed is also handled
            // by re-invoking the prompt when there's a waiting Service Worker
            if (registration.waiting) {
                invokeServiceWorkerUpdateFlow(registration);
            }

            // detect Service Worker update available and wait for it to become installed
            registration.addEventListener("updatefound", () => {
                if (registration.installing) {
                    // wait until the new Service worker is actually installed (ready to take over)
                    registration.installing.addEventListener("statechange", () => {
                        if (registration.waiting) {
                            // if there's an existing controller (previous Service Worker), show the prompt
                            if (navigator.serviceWorker.controller) {
                                invokeServiceWorkerUpdateFlow(registration);
                            } else {
                                // otherwise it's the first install, nothing to do
                                console.log("Service Worker initialized for the first time");
                            }
                        }
                    });
                }
            });

            let refreshing = false;
            // detect controller change and refresh the page 
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (!refreshing) {
                    window.location.reload();
                    refreshing = true;
                }
            });
        });
    }
};
// let newWorker;
// const InitSW = () => {
//     if ('serviceWorker' in navigator) {
//         const swPath = './service-worker.js';
//         navigator.serviceWorker.register(swPath)
//             .then(reg => {
//                 console.log('Service worker registered!');
//                 if (PWAInstalled()) {
//                     console.log('PWAInstalled');
//                 }

//                 reg.addEventListener('updatefound', () => {
//                     // newWorker = reg.installing;
//                     console.log('updatefound');
//                     // reg.update();
//                 });

//                 // reg.update();
//             })
//             .catch(function (e) {
//                 console.error('Service worker registration failed:', e);
//             });

//         // let refreshing;
//         navigator.serviceWorker.addEventListener('controllerchange', function () {
//             console.log('controllerchange');
//             // if (refreshing) return;
//             // window.location.reload();
//             // refreshing = true;
//         });
//     } else {
//         console.warn('Service workers are not supported.');
//     }
// }

export default PwaSettings;
