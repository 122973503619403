module.exports.Capitalize = (input) => {
    if (input == null) return "";
    const words = input.split(" ").filter(x => x != "").map(x => x.toLowerCase());
    const result = [];
    words.forEach(word => {
        result.push(word.charAt(0).toUpperCase() + word.slice(1));
    });

    return result.join(" ");
};

module.exports.GetClonedObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};
