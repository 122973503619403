<template>
    <a class="reset-tag-a color-white d-flex align-center" :href="urlToLink">
        <svg-icon type="mdi" :path="mdiWeb" class="mr-2" :width="width" :height="height"></svg-icon>
    </a>
</template>
<script>
import { mdiWeb } from "@mdi/js";
export default {
    props: {
        width: {
            default: "24px",
            type: String
        },
        height: {
            default: "24px",
            type: String
        },
        urlToLink: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            mdiWeb
        };
    }
};
</script>
<style scoped>
svg{
    cursor:pointer;
}
</style>
