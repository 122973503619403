export default {
    namespaced: true,
    state: {
        enableGeolocation: false,
    },
    getters: {
        getGeolocationEnablement(state) {
            return state.enableGeolocation;
        },
    },
    mutations: {
        setGeolocationEnablement(state, value) {
            state.enableGeolocation = value;
        },
    },
    actions: {
        setGeolocationEnablement({ commit }, value) {
            commit("setGeolocationEnablement", value);
        },
    },
};