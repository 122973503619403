import SatelitalImageService from "@/services/satelital-images.services";
import * as DateHelpers from "@/helpers/date.helpers";

const getDefaultState = () => {
    return {
        dataFromApi: {
            satelitalImages: {
                location: [],
                images: []
            },
            fieldId: 0,
            fieldName: "",
            producerName: "",
            producerId: 0,
            toolFieldId: 0,
        },
        maxCloudCoverage: 0,
        numberOfImagesPerMonth: 2,
        dateFrom: null,
        dateTo: null,
        imageType: "NDVI",
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getDataFromApi(state) {
            return state.dataFromApi;
        },
        getFilteredImages(state) {
            const validImages = state.dataFromApi.satelitalImages.images.filter((image) => image.absoluteImagePath != "");
            const sortedImages = validImages.sort((a, b) => a.date > b.date ? 1 : -1);
            return sortedImages;
        },
        getNumberOfAllImagesFound(state) {
            const validImages = state.dataFromApi.satelitalImages.images.filter((image) => image.absoluteImagePath != "");
            return validImages.length;
        },
        getMaxCloudCoverage(state) {
            return state.maxCloudCoverage;
        },
        getNumberOfImagesPerMonth(state) {
            return state.numberOfImagesPerMonth;
        },
        getDateFrom(state) {
            return state.dateFrom;
        },
        getDateTo(state) {
            return state.dateTo;
        },
        getImageType(state) {
            return state.imageType;
        }
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, getDefaultState());
        },
        SetSatelitalImagesFromApi(state, dataFromApi) {
            if (!dataFromApi) return;
            for (const image of dataFromApi.satelitalImages.images) {
                image.checked = false;
                image.location = dataFromApi.satelitalImages.location;
            }

            state.dataFromApi.satelitalImages.location = dataFromApi.satelitalImages.location;
            state.dataFromApi.satelitalImages.images = dataFromApi.satelitalImages.images;
            state.dataFromApi.fieldId = dataFromApi.fieldId;
            state.dataFromApi.fieldName = dataFromApi.fieldName;
            state.dataFromApi.producerName = dataFromApi.producerName;
            state.dataFromApi.producerId = dataFromApi.producerId;
        },
        SetMaxCloudCoverage(state, maxCloudCoverage) {
            if (maxCloudCoverage < 0) return state.maxCloudCoverage = 0;
            if (maxCloudCoverage > 100) return state.maxCloudCoverage = 100;
            state.maxCloudCoverage = maxCloudCoverage;
        },
        SetNumberOfImagesPerMonth(state, numberOfImagesPerMonth) {
            state.numberOfImagesPerMonth = numberOfImagesPerMonth;
        },
        SetCheckedImage(state, { imageId, checked }) {
            const images = state.dataFromApi.satelitalImages.images;
            const image = images.find((image) => image.imageId == imageId);
            if (image) image.checked = checked;
        },
        SetDateFrom(state, dateFrom) {
            state.dateFrom = dateFrom;
        },
        SetDateTo(state, dateTo) {
            state.dateTo = dateTo;
        },
        SetImageType(state, imageType) {
            state.imageType = imageType;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async SetSatelitalImagesFromApiAsync({ commit, state }, { toolFieldId }) {
            const dataFromApi = await SatelitalImageService.GetSatelitalImagesAsync(
                toolFieldId,
                DateHelpers.GetYYYYMMDDFormat(new Date(state.dateFrom)),
                DateHelpers.GetYYYYMMDDFormat(new Date(state.dateTo)),
                state.imageType,
                state.maxCloudCoverage,
                state.numberOfImagesPerMonth
            );
            commit("SetSatelitalImagesFromApi", dataFromApi);
        },
        SetMaxCloudCoverage({ commit }, maxCloudCoverage) {
            commit("SetMaxCloudCoverage", maxCloudCoverage);
        },
        SetNumberOfImagesPerMonth({ commit }, numberOfImagesPerMonth) {
            commit("SetNumberOfImagesPerMonth", numberOfImagesPerMonth);
        },
        SetCheckedImage({ commit }, { imageId, checked }) {
            commit("SetCheckedImage", { imageId, checked });
        },
        SetDateFrom({ commit }, dateFrom) {
            commit("SetDateFrom", dateFrom);
        },
        SetDateTo({ commit }, dateTo) {
            commit("SetDateTo", dateTo);
        },
        SetImageType({ commit }, imageType) {
            commit("SetImageType", imageType);
        }
    },
};
